<template>
    <div class="SignUpView">
        <v-container fluid class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <h1>Hello!</h1>
              <div class="font-weight-light">You are not registered yet</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div>Please, Scan with you ID4G+</div>
              <div>
                <v-progress-linear indeterminate height="10" color="white" v-if="!qr"></v-progress-linear>
                <v-img :src="qr" max-height="250px" max-width="250px" contain class="rounded-xl" v-if="qr" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="end" align="right">
              <v-img max-height="100px" max-width="200px" contain class="d-inline-flex mx-2" :src="require('@/assets/p4g_empowered_white.svg')"  />
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_SignUpView.scss'
export default {
  name: 'SignUpView',
  data () {
    return {
      qr: false
    }
  },
  mounted () {
    this.getChallenge()
  },
  methods: {
    async getChallenge () {
      const result = await this.$axios.post('https://4good.network/api/identification')
      const challenge = result.data
      this.qr = challenge.qr
      if (challenge) {
        try {
          let wait = await this.$axios.get('https://4good.network/api/identification/' + challenge.hash + '/wait')
          if (wait.data.state === 'scanning' || wait.data.state === 'waiting') {
            this.qr = null
            wait = await this.$axios.get('https://4good.network/api/identification/' + challenge.hash + '/wait')
          }
          if (wait.data.state === 'success') {
            this.$router.push({ name: this.$route.meta.route })
          }
          this.getChallenge()
        } catch (e) {
          this.qr = null
          this.getChallenge()
        }
      }
    }
  }
}
</script>
