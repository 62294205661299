<template>
    <div class="ConfirmComponent fade-in-fwd">
      <div class="Center" align="center" v-if="!loading">
        <v-img v-if="!$device.mobile && !loading && qr" :src="qr" contain width="300px" height="300px"  style="border-radius: 15px" class="fade-in-fwd Image" />
        <div v-if="$device.mobile"><v-btn :href="link" class="rounded-lg" icon x-large height="128"><v-img max-height="128" contain :src="require('@/assets/i4g.svg')" /></v-btn></div>
        <div class="Description mt-5" v-if="!$device.mobile">Scan with ID4Good for Verify</div>
        <div class="Description mt-5" v-if="$device.mobile">Click for open ID4Good for Verify</div>
        <v-btn @click="cancel()" class="mt-5 rounded-lg">Cancel</v-btn>
      </div>
      <div class="Center" align="center" v-if="loading">
        <div v-if="loading && !success && !failed">
          <v-progress-linear></v-progress-linear>
          <small class="Description mt-1">Progressing</small>
          <v-btn @click="cancel()" class="mt-5 rounded-lg">Cancel</v-btn>
        </div>
        <div v-if="success">
          <SuccessIcon class="Image" style="transform: scale(1.5)" />
          <small class="Description mt-1">Verify Success</small>
        </div>
        <div v-if="failed">
          <FailIcon class="Image" style="transform: scale(1.5)" />
          <small class="Description mt-1">Verify Failed</small>
        </div>
      </div>
    </div>
</template>

<script>
import './_ConfirmComponent.scss'
import { generateQR, getChallenge, getIdentity, waitChallenge } from 'id4good.auth'
import SuccessIcon from '@/components/Icons/SuccessIcon/SuccessIcon'
import FailIcon from '@/components/Icons/FailIcon/FailIcon'

export default {
  name: 'ConfirmComponent',
  components: { FailIcon, SuccessIcon },
  data () {
    return {
      loading: false,
      link: null,
      qr: null,
      success: false,
      failed: false
    }
  },
  mounted () {
    this.getID()
  },
  methods: {
    confirm () {
      this.success = true
      setTimeout(() => {
        this.$emit('response', true)
      }, 2000)
    },
    reject () {
      this.failed = true
      setTimeout(() => {
        this.$emit('response', false)
      }, 2000)
    },
    cancel () {
      this.loading = true
      this.failed = true
      setTimeout(() => {
        this.$emit('response', false)
      }, 2000)
    },
    async getID () {
      const challenge = await getChallenge('wallet4good.com')
      if (this.$device.mobile) {
        if (this.$device.ios) {
          this.link = 'https://id4good.com/' + challenge.code
        }
        if (this.$device.android) {
          this.link = 'id4good://wallet4good/' + challenge.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
        }
      } else {
        this.qr = await generateQR(challenge.code)
      }
      // console.log('load')
      const expire = setTimeout(() => {
        this.getID()
      }, 5 * 60000)
      waitChallenge(challenge.hash, 0).then(async (result) => {
        this.loading = true
        clearTimeout(expire)
        const identity = await getIdentity(challenge.hash)
        if (identity) {
          this.confirm()
        } else {
          this.reject()
        }
      })
    }
  }
}
</script>
