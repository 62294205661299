<template>
    <div class="LoginView">
      <div :class="{ Logo: true, 'slide-in-top': true, 'slide-out-top': finish }">
        <v-container fluid class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <v-img max-width="300px" width="80%" :src="require('@/assets/p4g.svg')" style="filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));" />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="Center" align="center" v-if="!loading">
        <v-img v-if="!$device.mobile && !loading && qr" :src="qr" contain width="300px" height="300px"  style="border-radius: 15px" class="fade-in-fwd" />
        <div v-if="$device.mobile"><v-btn :href="link" class="rounded-lg" icon x-large height="128"><v-img max-height="128" contain :src="require('@/assets/i4g.svg')" /></v-btn></div>
        <div class="Description mt-5" v-if="!$device.mobile">Scan with ID4G+ for Access to Dashboard</div>
        <div class="Description mt-5" v-if="$device.mobile">Click for open ID4G+ for access to DashboardD</div>
      </div>
      <div class="Center" align="center" v-if="loading">
        <div v-if="loading && !wallet">
          <v-progress-linear></v-progress-linear>
          <small class="Description mt-1">Progressing</small>
        </div>
        <div v-if="wallet">
          <SuccessIcon />
          <small class="Description mt-1">Login Success</small>
        </div>
      </div>
      <div :class="{ Footer: true, 'slide-in-bottom': true, 'slide-out-bottom': finish }">
        <v-container fluid class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <v-img max-width="150px" :src="require('@/assets/empowered.svg')"  />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
</template>

<script>
import './_LoginView.scss'
import { generateQR, getChallenge, getIdentity, waitChallenge } from 'id4good.auth'
import { Wallet } from 'ethers'
import SuccessIcon from '@/components/Icons/SuccessIcon/SuccessIcon'

export default {
  name: 'LoginView',
  components: { SuccessIcon },
  data () {
    return {
      link: null,
      qr: null,
      finish: false,
      loading: false,
      wallet: false
    }
  },
  mounted () {
    this.getID()
  },
  methods: {
    dashboard () {
      setTimeout(() => {
        this.finish = true
      }, 500)
      setTimeout(() => {
        this.$router.push({ name: 'main' })
      }, 2000)
    },
    async getID () {
      if (this.wallet) {
        return
      }
      const challenge = await getChallenge('wallet4good.com')
      if (this.$device.mobile) {
        if (this.$device.ios) {
          this.link = 'https://id4good.com/' + challenge.code
        }
        if (this.$device.android) {
          this.link = 'id4good://wallet4good/' + challenge.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
        }
      } else {
        this.qr = await generateQR(challenge.code)
      }
      // console.log('load')
      const expire = setTimeout(() => {
        this.getID()
      }, 5 * 60000)
      waitChallenge(challenge.hash, 0).then(async (result) => {
        this.loading = true
        clearTimeout(expire)
        const identity = await getIdentity(challenge.hash)
        if (identity.data.data !== undefined) {
          try {
            const data = JSON.parse(identity.data.data)
            const wallet = new Wallet(data.privateKey)
            this.wallet = wallet
          } catch (e) {
            const data = identity.data.data
            const wallet = new Wallet(data.privateKey)
            this.wallet = wallet
          }
          this.dashboard()
        }
      })
    }
  }
}
</script>
