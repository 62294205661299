<template>
    <div class="QuickMenuComponent">
      <v-container fluid>
        <v-row>
          <v-col align-self="center" align="center">
            <v-btn to="/dashboard/main"  active-class="activeButton" height="30" class="rounded-lg mx-2">Overview</v-btn>
            <v-btn to="/dashboard/business"  active-class="activeButton" height="30" class="rounded-lg mx-2">Business</v-btn>
            <v-btn to="/dashboard/transactions"  active-class="activeButton" height="30" class="rounded-lg mx-2">Transactions</v-btn>
            <v-btn to="/dashboard/devices"  active-class="activeButton" height="30" class="rounded-lg mx-2">Devices</v-btn>
            <v-btn to="/dashboard/finance"  active-class="activeButton" height="30" class="rounded-lg mx-2">Finance</v-btn>
            <v-btn to="/dashboard/profile"  active-class="activeButton" height="30" class="rounded-lg mx-2">Profile</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_QuickMenuComponent.scss'

export default {
  name: 'QuickMenuComponent'
}
</script>
