<template>
    <div class="CreateView">
      <div ref="default" :class="{ Default: true, 'slide-in-left': $vuetify.breakpoint.width > 960, 'slide-in-top': $vuetify.breakpoint.width <= 960, 'slide-out-left': finish }">
        <v-container fluid class="fill-height">
          <v-row dense>
            <v-col align-self="center" align="center">
              <v-img :class="{ 'my-5': panelHeight > 30 }" max-width="300px" width="80%" max-height="50px" contain :src="require('@/assets/p4g.svg')" style="filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center">
              <div align="left" class="PaymentDetail">
                <v-container>
                  <v-row v-if="panelHeight > 45">
                    <v-col align-self="center" align="center">
                      <div class="PartnerText">{{ merchant }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5" v-if="panelHeight > 35">
                    <v-col>
                      <div class="Separator"></div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 55">
                    <v-col class="pa-0">
                      <div class="SubtotalText">Amount</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText">USD$ {{ amount }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 55">
                    <v-col class="pa-0">
                      <div class="SubtotalText">Service Fee ({{ fee }}%)</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText">USD$ {{ parseFloat(amount * fee / 100 ).toFixed(2) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-5" v-if="panelHeight > 55">
                    <v-col class="pa-0">
                      <div class="SubtotalText font-weight-bold">Subtotal</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText font-weight-bold">USD$ {{ subtotal.toFixed(2) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 70 && tax > 0">
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">{{ taxType.toUpperCase() }} ({{ getCountryFlag(country) }} {{ tax.toFixed(2) }}%)</div>
                      <small v-if="state"> {{ getStateName(this.state) }}</small>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">USD$ {{ parseFloat(amount * tax / 100 ).toFixed(2) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="pa-0">
                      <div class="TotalText">Total</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="TotalText">USD$ {{ total.toFixed(2) }}</div>
                      <div class="CryptoTotalText d-inline-flex" v-if="$vuetify.breakpoint.height > 670 || panelHeight > 50">
                        <span class="mr-2" v-if="$vuetify.breakpoint.width > 960">*</span> <div class="scroller">
                          <span>
                            <div v-if="getEquivalentCurrency(getCountryCurrency(this.country))">{{ getCountryCurrency(this.country) }}$ ~{{ parseFloat(getEquivalentCurrency(getCountryCurrency(this.country)) * total).toFixed(2) }}</div>
                            <div>APTA$ ~{{ parseFloat(getEquivalentCurrency('APTA') * total).toFixed(3) }}</div>
                            <div>BTC$ ~{{ parseFloat(getEquivalentCurrency('BTC') * total).toFixed(5) }}</div>
                            <div>ETH$ ~{{ parseFloat(getEquivalentCurrency('ETH') * total).toFixed(4) }}</div>
                          </span>
                      </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5" v-if="$vuetify.breakpoint.width > 960">
                    <v-col>
                      <div class="CryptoTotalText font-italic">* Price in others Currencies</div>
                    </v-col>
                  </v-row>
                  <v-row v-if="panelHeight > 40">
                    <v-col>
                      <div class="Separator"></div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>
          </v-row>
          <v-row dense v-if="panelHeight > 90">
            <v-col align-self="center" align="center">
              <v-btn text class="mx-2" x-small @click="terms = !terms">Terms</v-btn>
              <v-btn text class="mx-2" x-small @click="privacy = !privacy">Privacy</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <div v-if="$vuetify.breakpoint.width < 960" class="Arrow pulsate-bck" align="center" ref="arrow" @touchmove="movePanel" @touchend="completePanel" @touchstart="startPanel"><v-icon v-if="panelHeight <= 50">mdi-chevron-double-down</v-icon><v-icon v-if="panelHeight > 50">mdi-chevron-double-up</v-icon></div>
      </div>
      <div :class="{ Lateral: true, 'slide-in-right': $vuetify.breakpoint.width > 960, 'slide-in-bottom': $vuetify.breakpoint.width <= 960, 'slide-out-right': finish }">
        <v-container fluid :class=" { 'fill-height': true, 'py-10': $vuetify.breakpoint.height > 800, 'pt-10': $vuetify.breakpoint.height <= 800 }">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="SectionTitle mb-5">Create Payment</div>
              <div class="Section">
                <v-text-field dark label="Merchant" v-model="merchant" outlined></v-text-field>
                <v-text-field dark label="Amount"  prefix="USD$" type="Number" v-model="amount" outlined></v-text-field>
                <v-autocomplete :items="countries" item-text="name" item-value="isoCode" dark outlined label="Country" v-model="country"></v-autocomplete>
                <v-autocomplete v-if="states" :items="states" item-text="name" item-value="isoCode" dark outlined label="State" v-model="state"></v-autocomplete>
                <div v-if="!address">
                  <v-progress-circular v-if="!qr" color="#1D4770" indeterminate width="20" size="200"></v-progress-circular>
                  <v-img v-if="!$device.mobile && !loadingIdentity && qr" :src="qr" contain width="250px" height="250px"  style="border-radius: 15px" class="fade-in-fwd" />
                  <div v-if="$device.mobile"><v-btn :href="link" class="rounded-lg" icon x-large height="128"><v-img max-height="128" contain :src="require('@/assets/i4g.svg')" /></v-btn></div>
                  <small class="Description mt-1" v-if="!$device.mobile">Scan to assign target ID</small>
                  <small class="Description mt-1" v-if="$device.mobile">Click to open ID4Good for assign target ID</small>
                </div>
                <div v-if="loadingIdentity && !address">
                  <v-progress-linear></v-progress-linear>
                  <small class="Description mt-1">Progressing</small>
                </div>
                <div v-if="address">
                  <SuccessIcon />
                  <small class="Description mt-1">ID Assigned</small>
                </div>
                <div class="mt-5">
                  <v-btn color="green" :disabled="!address" x-large class="rounded-lg">Create</v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div class="SeparatorDark mb-5"></div>
              <v-img max-width="150px" :src="require('@/assets/empowered.svg')"  />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
</template>

<script>
import './_CreateView.scss'
import { Country, State } from 'country-state-city'
import { generateQR, getChallenge, getIdentity, waitChallenge } from 'id4good.auth'
import { Wallet } from 'ethers'
import SuccessIcon from '@/components/Icons/SuccessIcon/SuccessIcon'

export default {
  name: 'CreateView',
  components: { SuccessIcon },
  data () {
    return {
      merchant: 'Merchant Demo',
      finish: false,
      amount: 10,
      step: 1,
      tax: 19,
      taxType: 'tax',
      fee: 1.5,
      panelHeight: 95,
      address: null,
      loadingIdentity: false,
      qr: null,
      link: '',
      country: null,
      state: null,
      states: null,
      countries: [],
      taxList: [],
      convert: null
    }
  },
  mounted () {
    this.$axios.get('https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json').then((result) => {
      this.convert = result.data
      this.convert.usd.apta = 1 / 8.02
    })
    this.countries = Country.getAllCountries()
    this.taxList = require('@/assets/tax.json')
    this.country = 'US'
    setTimeout(() => { this.state = 'CA' }, 100)
    if (this.$vuetify.breakpoint.width < 960) {
      setTimeout(() => {
        this.panelHeight = 18
        this.completePanel()
      }, 2000)
    }
    this.getID()
    console.log('ready')
  },
  watch: {
    country: {
      deep: true,
      handler: function (value) {
        this.tax = 0
        this.taxType = 'tax'
        this.states = null
        this.state = null
        if (this.taxList[value]) {
          this.tax = this.taxList[value].rate * 100
          this.taxType = this.taxList[value].type
          if (this.taxList[value].states) {
            this.states = State.getStatesOfCountry(this.country)
          }
        }
      }
    },
    state: {
      deep: true,
      handler: function (value) {
        if (!value) {
          return
        }
        this.tax = 0
        this.taxType = 'tax'
        if (this.taxList[this.country].states) {
          if (this.taxList[this.country].states[value]) {
            this.tax = this.taxList[this.country].states[value].rate * 100
            this.taxType = this.taxList[this.country].states[value].type
          }
        }
      }
    }
  },
  computed: {
    subtotal () {
      return parseFloat(parseFloat(this.amount) + parseFloat(this.amount * this.fee / 100))
    },
    total () {
      return parseFloat(this.subtotal + this.subtotal * this.tax / 100)
    }
  },
  methods: {
    async getID () {
      if (this.address) {
        return
      }
      const challenge = await getChallenge('wallet4good.com')
      if (this.$device.mobile) {
        if (this.$device.ios) {
          this.link = 'https://id4good.com/' + challenge.code
        }
        if (this.$device.android) {
          this.link = 'id4good://wallet4good/' + challenge.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
        }
      } else {
        this.qr = await generateQR(challenge.code)
      }
      // console.log('load')
      const expire = setTimeout(() => {
        this.getID()
      }, 5 * 60000)
      waitChallenge(challenge.hash, 0).then(async (result) => {
        this.loadingIdentity = true
        clearTimeout(expire)
        const identity = await getIdentity(challenge.hash)
        if (identity.data.data !== undefined) {
          try {
            const data = JSON.parse(identity.data.data)
            const wallet = new Wallet(data.privateKey)
            this.address = wallet.getAddress()
          } catch (e) {
            const data = identity.data.data
            const wallet = new Wallet(data.privateKey)
            this.address = wallet.getAddress()
          }
        }
      })
    },
    startPanel () {
      console.log('start')
      document.body.style.overflow = 'hidden'
    },
    completePanel () {
      document.body.style.overflow = 'scroll'
      console.log('complete')
      if (this.panelHeight < 50) {
        this.panelHeight = 18
      }
      if (this.panelHeight >= 50) {
        this.panelHeight = 95
      }
      this.$refs.default.style.height = this.panelHeight + '%'
    },
    movePanel (event) {
      let percent = parseFloat(event.targetTouches[0].clientY / window.innerHeight).toFixed(2) * 100
      if (percent > 95) {
        percent = 95
      }
      if (percent < 18) {
        percent = 18
      }
      this.panelHeight = percent
      this.$refs.default.style.height = percent + '%'
    },
    getEquivalentCurrency (code) {
      if (!this.convert) {
        return null
      }
      const find = this.convert.usd[code.toLowerCase()]
      if (find) {
        return find
      }
      return null
    },
    getCountryCurrency () {
      const find = this.countries.find(item => item.isoCode === this.country)
      if (find) {
        return find.currency
      }
      return 'USD'
    },
    getCountryName () {
      const find = this.countries.find(item => item.isoCode === this.country)
      if (find) {
        return find.name
      }
      return ''
    },
    getStateName () {
      const find = this.states.find(item => item.isoCode === this.state)
      if (find) {
        return find.name
      }
      return ''
    },
    getCountryFlag () {
      const find = this.countries.find(item => item.isoCode === this.country)
      if (find) {
        return find.flag
      }
      return ''
    }
  }
}
</script>
