export default {
  data () {
    return {
      amount: 0,
      tips: 10,
      tax: 19,
      fee: 0,
      charity: 5,
      network: 0,
      decimals: 2
    }
  },
  computed: {
    numericFee () {
      return parseFloat(parseFloat(this.amount) * this.fee / 100)
    },
    numericTax () {
      return parseFloat(this.subtotal * this.tax / 100)
    },
    numericTips () {
      return parseFloat(this.amount * this.tips / 100)
    },
    numericCharity () {
      return parseFloat(this.amount * this.charity / 100)
    },
    subtotal () {
      if (parseFloat(this.amount) === 0) {
        return 0
      }
      return parseFloat(parseFloat(this.amount) / (1 + this.tax / 100))
    },
    total () {
      return parseFloat(this.amount + this.numericTips + this.numericCharity)
    }
  },
  methods: {
    fixedNumber (value) {
      const factor = Math.pow(10, this.decimals)
      return parseFloat(parseInt(value * factor) / factor).toFixed(this.decimals)
    }
  }
}
