import Vue from 'vue'
import VueRouter from 'vue-router'
import LoadingView from '@/views/LoadingView/LoadingView'
import HomeView from '@/views/HomeView/HomeView'
import CreateView from '@/views/CreateView/CreateView'
import POSView from '@/views/POSView/POSView'
import LoginView from '@/views/LoginView/LoginView'
import DashboardView from '@/views/DashboardView/DashboardView'
import MainView from '@/views/DashboardView/MainView/MainView'
import BusinessView from '@/views/DashboardView/BusinessView/BusinessView'
import PublicView from '@/views/DashboardView/BusinessView/PublicView/PublicView'
import PaymentView from '@/views/DashboardView/BusinessView/PaymentView/PaymentView'
import TipsAndCharityView from '@/views/DashboardView/BusinessView/TipsAndCharityView/TipsAndCharityView'
import TransactionsView from '@/views/DashboardView/TransactionsView/TransactionsView'
import BalanceView from '@/views/DashboardView/TransactionsView/BalanceView/BalanceView'
import WizardView from '@/views/DashboardView/WizardView/WizardView'
import ProfileView from '@/views/DashboardView/ProfileView/ProfileView'
import PermissionsView from '@/views/DashboardView/BusinessView/PermissionsView/PermissionsView'
import CheckoutView from '@/views/CheckoutView/CheckoutView'
import POSSambaxView from '@/views/POSSambaxView/POSSambaxView.vue'
import POSBoozeBossView from '@/views/POSBoozeBossView/POSBoozeBossView.vue'
import POSCardSmartView from '@/views/POSCardSmartView/POSCardSmartView.vue'
import SignUpView from '@/views/SignUpView/SignUpView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loading',
    component: LoadingView
  },
  {
    path: '/dashboard/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    children: [
      {
        path: '/dashboard/wizard',
        name: 'Wizard',
        component: WizardView
      },
      {
        path: '/dashboard/main',
        name: 'main',
        component: MainView
      },
      {
        path: '/dashboard/business',
        redirect: to => { return '/dashboard/business/main' }
      },
      {
        path: '/dashboard/business/main',
        name: 'business',
        component: BusinessView
      },
      {
        path: '/dashboard/business/public',
        name: 'businessPublic',
        component: PublicView
      },
      {
        path: '/dashboard/business/tipsandcharity',
        name: 'businessTipsAndCharity',
        component: TipsAndCharityView
      },
      {
        path: '/dashboard/business/payment',
        name: 'businessPayment',
        component: PaymentView
      },
      {
        path: '/dashboard/business/permissions',
        name: 'Permissions',
        component: PermissionsView
      },
      {
        path: '/dashboard/transactions',
        redirect: to => { return '/dashboard/transactions/main' }
      },
      {
        path: '/dashboard/transactions/main',
        name: 'Transactions',
        component: TransactionsView
      },
      {
        path: '/dashboard/transactions/balance',
        name: 'Balance',
        component: BalanceView
      },
      {
        path: '/dashboard/profile',
        redirect: to => { return '/dashboard/profile/main' }
      },
      {
        path: '/dashboard/profile/main',
        name: 'Profile',
        component: ProfileView
      }
    ]
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView
  },
  {
    path: '/pos',
    name: 'pos',
    component: POSView
  },
  {
    path: '/pos-sambax',
    name: 'pos-sambax',
    component: POSSambaxView
  },
  {
    path: '/pos-boozeBoss',
    name: 'pos-boozeBoss',
    component: SignUpView,
    meta: {
      route: 'pos-boozeBoss-start'
    }
  },
  {
    path: '/pos-boozeBoss-start',
    name: 'pos-boozeBoss-start',
    component: POSBoozeBossView
  },
  {
    path: '/pos-cardSmart',
    name: 'pos-cardSmart',
    component: SignUpView,
    meta: {
      route: 'pos-cardSmart-start'
    }
  },
  {
    path: '/pos-cardSmart-start',
    name: 'pos-cardSmart-start',
    component: POSCardSmartView
  },
  {
    path: '/create',
    name: 'create',
    component: CreateView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children: [
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
