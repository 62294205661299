<template>
    <div class="PaymentView">
      <QuickMenuComponent />
      <v-container>
        <v-row>
          <v-col align-self="center" align="center">
            <QuickMenuBusinessComponent />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="SectionTitle">
              Payment Information
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="Section">
              <v-tabs
                v-model="tab"
                icons-and-text
                centered
                background-color="transparent"
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#bank">
                  Bank
                  <v-icon>mdi-bank</v-icon>
                </v-tab>
                <v-tab href="#stablecoin">
                  Stable Coin
                  <v-icon>mdi-circle-multiple</v-icon>
                </v-tab>
                <v-tabs-items v-model="tab" style="background-color: transparent" class="mt-10">
                  <v-tab-item value="bank">
                    <div align="left" class="SectionTitle mb-5">Bank Information</div>
                    <v-autocomplete prepend-icon="mdi-bank" label="Bank Name" filled outlined></v-autocomplete>
                    <div class="d-flex">
                      <v-autocomplete class="mx-2" prepend-icon="mdi-cash-100" label="Account type" type="email" filled outlined></v-autocomplete>
                      <v-text-field class="mx-2" prepend-icon="mdi-account-cash-outline" label="Account Number" filled outlined></v-text-field>
                    </div>
                    <v-text-field prepend-icon="mdi-bank-transfer" label="SWIFT Code" filled outlined></v-text-field>
                    <v-text-field prepend-icon="mdi-email" label="Notify Email" filled outlined></v-text-field>
                    <div align="right" class="my-5">
                      <v-btn class="px-10" x-large color="success" @click="confirm = true">Save</v-btn>
                    </div>
                    <div class="Separator" />
                    <div align="left" class="SectionTitle mb-5" @click="confirm = true">Withdrawal</div>
                    <div class="d-flex">
                      <v-autocomplete class="mx-2" :items="availableAccount" prepend-icon="mdi-account-cash-outline" label="Account Available" type="email" filled outlined></v-autocomplete>
                      <div class="Balance">
                        <div class="BalanceTitle">Balance</div>
                        USD$ 10490
                      </div>
                    </div>
                    <v-text-field class="mx-2" prepend-icon="mdi-cash" label="Amount" prefix="USD$" value="0" filled outlined></v-text-field>
                    <div align="right" class="my-5">
                      <v-btn class="px-10" x-large color="primary" @click="confirm = true">Request Withdrawal</v-btn>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="stablecoin">
                    <v-autocomplete prepend-icon="mdi-bank" label="Stable Coin" filled outlined :items="coins"></v-autocomplete>
                    <v-autocomplete prepend-icon="mdi-web" label="Network" filled outlined :items="network"></v-autocomplete>
                    <v-text-field class="mx-2" prepend-icon="mdi-account-cash-outline" label="Destination Address" filled outlined></v-text-field>
                    <div align="right" class="my-5">
                      <v-btn class="px-10" x-large color="success" @click="confirm = true">Save</v-btn>
                    </div>
                    <div class="Separator" />
                    <div align="left" class="SectionTitle mb-5" @click="confirm = true">Withdrawal</div>
                    <div class="d-flex">
                      <v-autocomplete class="mx-2" :items="availableAccount" prepend-icon="mdi-account-cash-outline" label="Account Available" type="email" filled outlined></v-autocomplete>
                      <div class="Balance">
                        <div class="BalanceTitle">Balance</div>
                        USD$ 10490
                      </div>
                    </div>
                    <v-text-field class="mx-2" prepend-icon="mdi-cash" label="Amount" prefix="USD$" value="0" filled outlined></v-text-field>
                    <div align="right" class="my-5">
                      <v-btn class="px-10" x-large color="primary" @click="confirm = true">Withdraw</v-btn>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
              <div align="left" class="SectionTitle mb-5" @click="confirm = true">Request/Transfer History</div>
              <v-data-table
                dense
                :headers="transactionHeaders"
                :items="transactionData"
                item-key="name"
                class="Table"
                style="background-color: transparent"
                dark
              ></v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <ConfirmComponent v-if="confirm" @response="finish()" />
    </div>
</template>

<script>
import './_PaymentView.scss'
import QuickMenuComponent from '@/components/QuickMenuComponent/QuickMenuComponent'
import QuickMenuBusinessComponent from '@/components/QuickMenuBusinessComponent/QuickMenuBusinessComponent'
import ConfirmComponent from '@/components/ConfirmComponent/ConfirmComponent'
export default {
  name: 'PaymentView',
  components: { ConfirmComponent, QuickMenuBusinessComponent, QuickMenuComponent },
  data () {
    return {
      tab: null,
      availableAccount: ['Main', 'VAT', 'Tips', 'Charity'],
      coins: ['T4G', 'USDT', 'BUSD', 'USDC'],
      network: ['4GoodNetwork', 'ERC20', 'TRC20'],
      confirm: false,
      transactionHeaders: [
        {
          text: 'Datetime',
          align: 'start',
          value: 'datetime'
        },
        { text: 'Amount', value: 'calories' },
        { text: 'Destination', value: 'calories' },
        { text: 'Status', value: 'iron' }
      ],
      transactionData: []
    }
  },
  methods: {
    finish (result) {
      this.confirm = false
    }
  }
}
</script>
