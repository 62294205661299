<template>
    <div class="QuickMenuTransactionsComponent">
      <v-btn class="mx-1 rounded-lg" text to="/dashboard/transactions/main">Transactions</v-btn>
      <v-btn class="mx-1 rounded-lg" text to="/dashboard/transactions/balance">Balance</v-btn>
    </div>
</template>

<script>
export default {
  name: 'QuickMenuTransactionsComponent'
}
</script>
