<template>
    <div class="WizardView">
        <v-container>
          <v-row>
            <v-col v-if="!loading" class="fade-in-fwd" align-self="center" align="center">
              <div class="SectionTitle my-10">
                Welcome to Pay4good
              </div>
              <div class="Description my-5">
                You must complete this information to start using the platform, this is a basic configuration, since you need to verify the identity and the business to operate without limitations.
              </div>
              <v-stepper v-model="step" class="Section" alt-labels>
                <v-stepper-header class="elevation-0">
                  <v-stepper-step
                    :complete="step > 1"
                    step="1"
                  >
                    Profile
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="step > 2"
                    step="2"
                  >
                    Business
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step :complete="step > 3" step="3">
                    Complete
                  </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <div class="mx-10">
                      <div class="SectionTitle mb-5" align="left">
                        Complete your Profile
                      </div>
                      <v-text-field prepend-icon="mdi-card-account-details" label="Personal ID" filled outlined></v-text-field>
                      <v-text-field prepend-icon="mdi-account" label="Name" filled outlined></v-text-field>
                      <v-text-field prepend-icon="mdi-map-marker" label="Personal Address" filled outlined></v-text-field>
                      <div class="d-flex">
                        <v-autocomplete prepend-icon="mdi-home-city-outline" class="mx-2" label="Country" filled outlined></v-autocomplete>
                        <v-autocomplete class="mx-2" label="State" filled outlined></v-autocomplete>
                        <v-autocomplete class="mx-2" label="City" filled outlined></v-autocomplete>
                        <v-text-field type="phone" class="mx-2" label="ZIP Code" filled outlined></v-text-field>
                      </div>
                      <v-text-field prepend-icon="mdi-phone" label="Contact Number" filled outlined></v-text-field>
                      <v-autocomplete prepend-icon="mdi-translate" class="mx-2" label="Language" filled outlined></v-autocomplete>
                    </div>
                    <v-btn class="mt-5 mx-2 rounded-lg" x-large color="#1D4770" dark @click="step++">Continue</v-btn>

                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <div class="SectionTitle mb-5" align="left">
                      Complete Business Information
                    </div>
                    <div class="mx-10">
                      <v-text-field prepend-icon="mdi-google-my-business" label="Business ID" filled outlined></v-text-field>
                      <v-text-field prepend-icon="mdi-google-my-business" label="Business Name" filled outlined></v-text-field>
                      <v-text-field prepend-icon="mdi-map-marker" label="Business Address" filled outlined></v-text-field>
                      <div class="d-flex">
                        <v-autocomplete prepend-icon="mdi-home-city-outline" class="mx-2" label="Country" filled outlined></v-autocomplete>
                        <v-autocomplete class="mx-2" label="State" filled outlined></v-autocomplete>
                        <v-autocomplete class="mx-2" label="City" filled outlined></v-autocomplete>
                        <v-text-field type="phone" class="mx-2" label="ZIP Code" filled outlined></v-text-field>
                      </div>
                      <v-text-field prepend-icon="mdi-phone" label="Contact Number" filled outlined></v-text-field>
                      <v-autocomplete :items="timezones" prepend-icon="mdi-map-clock" class="mx-2" label="TimeZone" filled outlined></v-autocomplete>
                    </div>
                    <v-btn class="mt-5 mx-2 rounded-lg" dark @click="step--">Back</v-btn>
                    <v-btn class="mt-5 mx-2 rounded-lg" x-large color="#1D4770" dark @click="step++">Continue</v-btn>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <div class="Title text-h1 my-10">
                      Congratulations
                    </div>
                    <div class="Description my-10">
                      You have completed the basic information to start using Pay4Good, as the next step I recommend you request to verify your identity and your company, in order to make the most of the platform.
                    </div>
                    <v-btn class="mt-5 mx-2 rounded-lg" dark @click="step--">Back</v-btn>
                    <v-btn class="mt-5 mx-2 rounded-lg" x-large color="#1D4770" dark @click="step++">Finish</v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
              <v-progress-linear style="max-width: 50%" v-if="finish" indeterminate height="10" class="my-16"></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      <div v-if="loading" class="Center">
        <v-img class="slide-in-top" style="animation-duration: 1s" :src="require('@/assets/_p4g.svg')" contain max-height="128px" height="128px" />
        <v-img class="slide-in-bottom" style="animation-duration: 1s" :src="require('@/assets/_p4g_text.svg')" contain max-height="128px" height="128px" />
      </div>
    </div>
</template>

<script>
import './_WizardView.scss'

export default {
  name: 'WizardView',
  data () {
    return {
      step: 1,
      timezones: [],
      loading: true,
      finish: false
    }
  },
  watch: {
    step: {
      deep: true,
      handler: function (value) {
        if (value === 4) {
          this.dashboard()
        }
      }
    }
  },
  mounted () {
    this.timezones = this.getTimezones()
    setTimeout(() => {
      this.loading = false
    }, 2000)
  },
  methods: {
    dashboard () {
      setTimeout(() => {
        this.finish = true
      }, 100)
      setTimeout(() => {
        this.$router.push({ name: 'main' })
      }, 2000)
    },
    getTimezones () {
      return Intl.supportedValuesOf('timeZone')
    }
  }
}
</script>
