<template>
    <div class="DashboardView">
      <v-app-bar class="Header" dark height="100">
        <v-img v-if="$vuetify.breakpoint.width > 600" :src="require('@/assets/p4g_empowered_white.svg')" contain max-height="80px" max-width="200px" class="Logo" />
        <v-img v-if="$vuetify.breakpoint.width <= 600" :src="require('@/assets/_p4g.svg')" contain max-height="50px" max-width="60px" class="Logo" />
        <v-spacer></v-spacer>
        <div class="Business" v-if="$route.name !== 'Wizard'">
          <v-autocomplete background-color="#131a25aa" class="Select" dense :items="business" item-value="id" item-text="name" solo hide-details v-model="selectBusiness"></v-autocomplete>
        </div>
        <div class="Balance" v-if="$route.name !== 'Wizard'">
          <div class="BalanceTitle">Balance</div>
          USD$ 10490
        </div>
        <v-btn v-if="$route.name !== 'Wizard'" @click="drawer = !drawer" icon><v-icon v-if="!drawer">mdi-menu</v-icon><v-icon v-if="drawer">mdi-menu-open</v-icon></v-btn>
      </v-app-bar>
      <v-navigation-drawer
        fixed
        right
        v-model="drawer"
        class="Menu"
        v-if="$route.name !== 'Wizard'"
      >
        <v-list
          rounded
          dense
        >
          <v-list-item
            v-for="item in items"
            :key="item.title"
            link
          >
            <v-list-item v-if="item.subItems.length === 0" :to="item.to">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-group
              v-if="item.subItems.length > 0"
              no-action
              :value="true"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(subItem, i) in item.subItems"
                :key="i"
                link
                style="padding-left: 40px"
                :to="subItem.to"
              >
                <v-list-item-icon>
                  <v-icon v-text="subItem.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-title v-text="subItem.title"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <div class="Content">
        <router-view></router-view>
      </div>
      <div class="Footer">
        <v-img v-if="$vuetify.breakpoint.width > 600" :src="require('@/assets/empowered.svg')" contain max-height="80px" max-width="100px" class="Logo" />
      </div>
    </div>
</template>

<script>
import './_DashboardView.scss'

export default {
  name: 'DashboardView',
  mounted () {
    console.log('init')
    console.log(document.documentElement.style.overflow = 'auto')
    document.html.style.overflow = 'auto !important'
  },
  data () {
    return {
      drawer: false,
      selectBusiness: 'demo',
      business: [
        { id: 'demo', name: 'Demo Store' },
        { id: 'demo2', name: 'Demo Merchant' }
      ],
      items: [
        { title: 'Overview', icon: 'mdi-view-dashboard', to: '/dashboard/main', subItems: [] },
        {
          title: 'Business',
          icon: 'mdi-google-my-business',
          to: '/dashboard/business',
          subItems: [
            { title: 'Information', icon: 'mdi-card-account-details', to: '/dashboard/business/main' },
            { title: 'Public', icon: 'mdi-earth', to: '/dashboard/business/public' },
            { title: 'Tips and Charity', icon: 'mdi-piggy-bank', to: '/dashboard/business/tipsandcharity' },
            { title: 'Payment', icon: 'mdi-cash-sync', to: '/dashboard/business/payment' }
          ]
        },
        {
          title: 'Transactions',
          icon: 'mdi-cash',
          to: '/dashboard/transactions',
          subItems: [
            { title: 'Currents', icon: 'mdi-view-list-outline', to: '/dashboard/business/main' },
            { title: 'Balance', icon: 'mdi-wallet', to: '/dashboard/business/balance' }
          ]
        },
        {
          title: 'Devices',
          icon: 'mdi-cellphone-nfc',
          to: '/dashboard/devices',
          subItems: [
            { title: 'Currents', icon: 'mdi-view-list-outline', to: '/dashboard/devices/list' },
            { title: 'Activate', icon: 'mdi-cellphone-check', to: '/dashboard/devices/activate' }
          ]
        },
        {
          title: 'Finance',
          icon: 'mdi-finance',
          to: '/dashboard/investments',
          subItems: [
            { title: 'Lending', icon: 'mdi-chart-pie', to: '/dashboard/finance/lending' },
            { title: 'Investment', icon: 'mdi-chart-line', to: '/dashboard/finance/investment' }
          ]
        },
        {
          title: 'Profile',
          icon: 'mdi-face-man-profile',
          to: '/dashboard/profile',
          subItems: []
        }
      ]
    }
  }
}
</script>
