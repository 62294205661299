<template>
    <div class="MainView">
        <QuickMenuComponent />
      <v-container>
        <v-row>
          <v-col>
            <v-alert
              :color="alert.color"
              dark
              :icon="alert.icon"
              border="left"
              prominent
              class="my-10 Alert"
            >
              <span v-html="alert.text"></span>
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="left">
            <div class="SectionTitle">Today</div>
            <div>
              <div class="Balance d-inline-flex">
                <div class="BalanceTitle">Volume</div>
                USD$ 1204.5
              </div>
              <div class="Balance d-inline-flex">
                <div class="BalanceTitle">Yesterday</div>
                USD$ 819.2
              </div>
            </div>
            <apexChart class="Chart" v-if="!loading" width="100%" height="300px" type="area" :options="chartDayOptions" :series="daySeries"></apexChart>
          </v-col>
          <v-col align-self="center" align="left">
            <div class="SectionTitle">Current Month</div>
            <div>
              <div class="Balance d-inline-flex">
                <div class="BalanceTitle">Volume</div>
                USD$ 9251.1
              </div>
              <div class="Balance d-inline-flex">
                <div class="BalanceTitle">Previous Month</div>
                USD$ 8253.7
              </div>
            </div>
            <apexChart class="Chart" v-if="!loading" width="100%" height="300px" type="area" :options="chartMonthOptions" :series="monthSeries"></apexChart>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="SectionTitle">Last Transactions</div>
            <div>
              <div class="Balance d-inline-flex">
                <div class="BalanceTitle">Successes</div>
                8 transactions
              </div>
              <div class="Balance d-inline-flex">
                <div class="BalanceTitle">Failed</div>
                2 transactions
              </div>
            </div>
            <v-data-table
              dense
              :headers="transactionHeaders"
              :items="transactionsData"
              item-key="name"
              class="Table"
              style="background-color: transparent"
              dark
            ></v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="SectionTitle">Devices</div>
            <div>
              <div class="Balance d-inline-flex">
                <div class="BalanceTitle">Total</div>
                20 devices
              </div>
              <div class="Balance d-inline-flex">
                <div class="BalanceTitle">Actives</div>
                15 devices
              </div>
            </div>
            <v-data-table
              dense
              :headers="transactionHeaders"
              :items="transactionsData"
              item-key="name"
              class="Table"
              style="background-color: transparent"
              dark
            ></v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <v-btn text class="mx-2" x-small @click="terms = !terms">Terms</v-btn>
            <v-btn text class="mx-2" x-small @click="privacy = !privacy">Privacy</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_MainView.scss'
import QuickMenuComponent from '@/components/QuickMenuComponent/QuickMenuComponent'
export default {
  name: 'MainView',
  components: { QuickMenuComponent },
  data () {
    return {
      loading: true,
      currentAlert: 0,
      alert: {
        color: '#1D4770',
        icon: 'mdi-human-greeting',
        text: 'Welcome, Your last login was <b>Jun 11 2022 15:51</b>'
      },
      alerts: [
        {
          color: '#1D4770',
          icon: 'mdi-human-greeting',
          text: 'Welcome, Your last login was <b>Jun 11 2022 15:51</b>'
        },
        {
          color: '#97392d',
          icon: 'mdi-alert-decagram-outline',
          text: 'There are devices <b>turned off</b> for more than 72 hours'
        },
        {
          color: '#4f4f4f',
          icon: 'mdi-credit-card-outline',
          text: 'A new means of payment has been added to the platform, <b>Paypal</b>, every day we grow more and increase the supported payment solutions in order to reach more and more users every day'
        }
      ],
      transactionHeaders: [
        {
          text: 'Datetime',
          align: 'start',
          value: 'datetime'
        },
        { text: 'Amount', value: 'calories' },
        { text: 'Fee', value: 'fat' },
        { text: 'Tips', value: 'carbs' },
        { text: 'Charity', value: 'protein' },
        { text: 'Status', value: 'iron' }
      ],
      deviceHeaders: [
        {
          text: 'Datetime',
          align: 'start',
          value: 'datetime'
        },
        { text: 'Amount', value: 'calories' },
        { text: 'Fee', value: 'fat' },
        { text: 'Tips', value: 'carbs' },
        { text: 'Charity', value: 'protein' },
        { text: 'Status', value: 'iron' }
      ],
      transactionsData: [
        {
          datetime: 'Jun 11 2022 15:51:19',
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: 'completed'
        },
        {
          datetime: 'Jun 11 2022 15:51:18',
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: 'reject'
        },
        {
          datetime: 'Jun 11 2022 15:50:19',
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: 'completed'
        },
        {
          datetime: 'Jun 11 2022 15:50:15',
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: 'failed'
        },
        {
          datetime: 'Jun 11 2022 15:50:10',
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: 'completed'
        },
        {
          datetime: 'Jun 11 2022 15:49:10',
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: 'completed'
        },
        {
          datetime: 'Jun 11 2022 15:49:05',
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: 'completed'
        },
        {
          datetime: 'Jun 11 2022 15:49:01',
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: 'completed'
        },
        {
          datetime: 'Jun 11 2022 15:48:01',
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: 'completed'
        },
        {
          datetime: 'Jun 11 2022 15:48:00',
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: 'completed'
        }
      ],
      month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      chartDayOptions: {
        colors: ['#23746b'],
        chart: {
          id: 'dayChart',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: []
        },
        tooltip: {
          x: {
            format: 'HH:ss'
          },
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return 'USD$ ' + value
            }
          }
        }
      },
      chartMonthOptions: {
        colors: ['#233874'],
        chart: {
          id: 'monthChart',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: []
        },
        tooltip: {
          x: {
            format: 'dd'
          },
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return 'USD$ ' + value
            }
          }
        }
      },
      daySeries: [{
        name: 'Volume',
        data: [0, 0, 0, 0, 0, 0, 0, 10, 25.1, 50.5, 109, 202, 180, 50.5, 109, 202, 185, 300, 320, 170, 40, 0, 1, 0]
      }],
      monthSeries: [{
        name: 'Volume',
        data: []
      }]
    }
  },
  mounted () {
    this.chartDayOptions.xaxis.categories = this.generateDay()
    this.chartMonthOptions.xaxis.categories = this.generateMonth()
    this.monthSeries[0].data = new Array(this.chartMonthOptions.xaxis.categories.length).fill().map(() => parseFloat(500 * Math.random()).toFixed(2))
    this.loadAlert()
    this.loading = false
  },
  methods: {
    loadAlert () {
      this.alert = this.alerts[this.currentAlert]
      this.currentAlert++
      if (this.currentAlert >= this.alerts.length) {
        this.currentAlert = 0
      }
      setTimeout(() => {
        this.loadAlert()
      }, 7000)
    },
    getCurrentMonth () {
      const time = new Date()
      return this.month[time.getMonth()]
    },
    generateMonth () {
      const time = new Date()
      const days = new Date(time.getFullYear(), time.getMonth() + 1, 0).getDate()
      const month = Object.keys(new Array(days).fill(0))
      month.push(month.length.toString())
      month.splice(0, 1)
      console.log(month)
      return month
    },
    generateDay () {
      const time = new Array(24).fill('')
      for (const item in time) {
        const date = new Date()
        console.log(date)
        date.setUTCHours(item)
        date.setUTCMinutes(0)
        date.setUTCSeconds(0)
        time[item] = date.toISOString()
        console.log(time[item])
      }
      return time
    }
  }
}
</script>
