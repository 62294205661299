<template>
    <div class="BusinessView">
      <QuickMenuComponent />
      <v-container>
        <v-row>
          <v-col align-self="center" align="center">
            <QuickMenuBusinessComponent />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="SectionTitle">
              Business Information
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="Section">
              <div align="right" class="mb-10 Verify">
                Pending verification <v-icon color="orange" large class="mx-2">mdi-check-decagram</v-icon>
              </div>
              <v-text-field prepend-icon="ID" label="ID" readonly filled outlined v-model="id" append-icon="mdi-content-copy"></v-text-field>
              <v-text-field prepend-icon="mdi-google-my-business" label="Business ID" filled outlined></v-text-field>
              <v-text-field prepend-icon="mdi-google-my-business" label="Business Name" filled outlined></v-text-field>
              <v-text-field prepend-icon="mdi-map-marker" label="Business Address" filled outlined></v-text-field>
              <div class="d-flex">
                <v-autocomplete prepend-icon="mdi-home-city-outline" class="mx-2" label="Country" filled outlined></v-autocomplete>
                <v-autocomplete class="mx-2" label="State" filled outlined></v-autocomplete>
                <v-autocomplete class="mx-2" label="City" filled outlined></v-autocomplete>
                <v-text-field type="phone" class="mx-2" label="ZIP Code" filled outlined></v-text-field>
              </div>
              <v-text-field prepend-icon="mdi-phone" label="Contact Number" filled outlined></v-text-field>
              <v-autocomplete :items="timezones" prepend-icon="mdi-map-clock" class="mx-2" label="TimeZone" filled outlined></v-autocomplete>
              <div align="right" class="my-5">
                <v-btn class="px-10 mx-2" large color="primary" :disabled="true">Request Verification</v-btn>
                <v-btn class="px-10 mx-2" x-large color="success">Save</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_BusinessView.scss'
import QuickMenuComponent from '@/components/QuickMenuComponent/QuickMenuComponent'
import QuickMenuBusinessComponent from '@/components/QuickMenuBusinessComponent/QuickMenuBusinessComponent'

export default {
  name: 'BusinessView',
  components: { QuickMenuBusinessComponent, QuickMenuComponent },
  data () {
    return {
      id: 'ID>>324232523523534546362>12310238940fsdjoij230jj',
      timezones: []
    }
  },
  mounted () {
    this.timezones = this.getTimezones()
  },
  methods: {
    getTimezones () {
      return Intl.supportedValuesOf('timeZone')
    }
  }
}
</script>
