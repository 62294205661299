<template>
    <div class="BalanceView">
      <QuickMenuComponent />
      <v-container>
        <v-row>
          <v-col align-self="center" align="center">
            <QuickMenuTransactionsComponent />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="SectionTitle">
              Balance Information
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="SectionNoFrame">
              <v-container>
                <v-row>
                  <v-col align-self="center" align="right">
                    <apexChart class="Chart" v-if="!loading" width="100%" height="400px" type="pie" :options="chartBalanceOptions" :series="balanceSeries"></apexChart>
                  </v-col>
                  <v-col align-self="center" align="left">
                    <div class="SectionTitle my-5">
                      Current Balance
                    </div>
                    <div class="Balance">
                      <div class="BalanceTitle">Main Account</div>
                      USD$ 9500
                    </div>
                    <div class="Balance">
                      <div class="BalanceTitle">VAT Account</div>
                      USD$ 500
                    </div>
                    <div class="Balance">
                      <div class="BalanceTitle">Tips Account</div>
                      USD$ 190
                    </div>
                    <div class="Balance">
                      <div class="BalanceTitle">Charity Account</div>
                      USD$ 400
                    </div>
                    <div class="Balance font-weight-bold">
                      <div class="BalanceTitle">Total</div>
                      USD$ 10490
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="mt-5">
                    <div class="SectionTitle" align="left">
                      Volume {{ currentYear }}
                    </div>
                    <div align="right" class="mb-4">
                      <v-btn class="mx-2" x-small @click="currentYear--">Previous Year</v-btn>
                      <v-btn v-if="currentYear < new Date().getFullYear()" class="mx-2" x-small @click="currentYear++">Next Year</v-btn>
                    </div>
                    <v-data-table
                      dense
                      :headers="transactionHeaders"
                      :items="transactionData"
                      item-key="name"
                      class="Table"
                      style="background-color: transparent"
                      dark
                      hide-default-footer
                    >
                      <template v-slot:[`item.total`]="{ item }">
                        <b>USD$ {{ item.total }}</b>
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        USD$ {{ item.amount }}
                      </template>
                      <template v-slot:[`item.vat`]="{ item }">
                        USD$ {{ item.vat }}
                      </template>
                      <template v-slot:[`item.tips`]="{ item }">
                        USD$ {{ item.tips }}
                      </template>
                      <template v-slot:[`item.charity`]="{ item }">
                        USD$ {{ item.charity }}
                      </template>
                      <template v-slot:footer>
                        <div class="v-data-footer BalanceDetail font-weight-bold">
                          <v-container fluid>
                            <v-row dense>
                              <v-col align="left"></v-col>
                              <v-col align="left">$USD {{ transactionData.reduce((partialSum, d) => partialSum + d.total, 0) }}</v-col>
                              <v-col align="left">$USD {{ transactionData.reduce((partialSum, d) => partialSum + d.amount, 0) }}</v-col>
                              <v-col align="left">$USD {{ transactionData.reduce((partialSum, d) => partialSum + d.vat, 0) }}</v-col>
                              <v-col align="left">$USD {{ transactionData.reduce((partialSum, d) => partialSum + d.tips, 0) }}</v-col>
                              <v-col align="left">$USD {{ transactionData.reduce((partialSum, d) => partialSum + d.charity, 0) }}</v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <apexChart class="Chart" v-if="!loading" width="80%" height="400px" type="area" :options="chartMonthOptions" :series="monthSeries"></apexChart>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_BalanceView.scss'
import QuickMenuComponent from '@/components/QuickMenuComponent/QuickMenuComponent'
import QuickMenuTransactionsComponent from '@/components/QuickMenuTransactionsComponent/QuickMenuTransactionsComponent'
export default {
  name: 'BalanceView',
  components: { QuickMenuTransactionsComponent, QuickMenuComponent },
  data () {
    return {
      loading: true,
      currentYear: 2022,
      transactionHeaders: [
        {
          text: 'Month',
          align: 'start',
          value: 'month'
        },
        { text: 'Total', value: 'total' },
        { text: 'Amount', value: 'amount' },
        { text: 'VAT', value: 'vat' },
        { text: 'Tips', value: 'tips' },
        { text: 'Charity', value: 'charity' }
      ],
      transactionData: [
        {
          month: 'January',
          total: 2000,
          amount: 1900,
          vat: 50,
          tips: 25,
          charity: 25
        },
        {
          month: 'February',
          total: 9000,
          amount: 1900,
          vat: 50,
          tips: 25,
          charity: 25
        },
        {
          month: 'March',
          total: 8750,
          amount: 1900,
          vat: 50,
          tips: 25,
          charity: 25
        },
        {
          month: 'April',
          total: 3758,
          amount: 1900,
          vat: 50,
          tips: 25,
          charity: 25
        },
        {
          month: 'May',
          total: 12000,
          amount: 1900,
          vat: 50,
          tips: 25,
          charity: 25
        },
        {
          month: 'June',
          total: 10490,
          amount: 1900,
          vat: 50,
          tips: 25,
          charity: 25
        }
      ],
      chartMonthOptions: {
        chart: {
          id: 'monthChart',
          stacked: true,
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return 'USD$ ' + value
            }
          }
        }
      },
      chartBalanceOptions: {
        legend: {
          position: 'bottom'
        },
        labels: ['Main', 'VAT', 'Tips', 'Charity'],
        chart: {
          id: 'balanceChart',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: 'smooth'
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return 'USD$ ' + value
            }
          }
        }
      },
      balanceSeries: [9500, 500, 100, 400],
      monthSeries: [{
        name: 'Amount',
        data: [2000, 9000, 8750, 3758, 12000, 10490, 0, 0, 0, 0, 0, 0]
      },
      {
        name: 'VAT',
        data: [200, 900, 875, 375, 1200, 1049, 0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Tips',
        data: [20, 90, 87, 37, 120, 104, 0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Charity',
        data: [25, 95, 80, 39, 116, 154, 0, 0, 0, 0, 0, 0]
      }]
    }
  },
  mounted () {
    this.loading = false
  }
}
</script>
