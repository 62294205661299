<template>
    <div class="HomeView">
      <div ref="default" :class="{ Default: true, 'slide-in-left': $vuetify.breakpoint.width > 960, 'slide-in-top': $vuetify.breakpoint.width <= 960, 'slide-out-left': finish }">
        <v-container fluid class="fill-height">
          <v-row dense>
            <v-col align-self="center" align="center">
              <div v-if="pos" class="PartnerText">SlapItOn <v-icon color="green" class="mx-1" size="28">mdi-check-decagram</v-icon></div>
              <v-img v-if="!pos" :class="{ 'my-5': panelHeight > 30 }" max-width="300px" width="80%" max-height="50px" contain :src="require('@/assets/p4g.svg')" style="filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center">
              <div align="left" class="PaymentDetail">
                <v-container>
                  <v-row v-if="panelHeight > 45 && !pos">
                    <v-col align-self="center" align="center">
                      <div class="PartnerText">SlapItOn <v-icon color="green" class="mx-1" size="28">mdi-check-decagram</v-icon></div>
                    </v-col>
                  </v-row>
                  <v-row class="mb-5" v-if="panelHeight > 35">
                    <v-col>
                      <div class="Separator"></div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 55">
                    <v-col class="pa-0">
                      <div class="SubtotalText">Amount</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText">USD$ {{ amount }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 55">
                    <v-col class="pa-0">
                      <div class="SubtotalText">Service Fee ({{ fee }}%)</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText">USD$ {{ parseFloat(amount * fee / 100 ).toFixed(2) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense class="mt-5" v-if="panelHeight > 55">
                    <v-col class="pa-0">
                      <div class="SubtotalText font-weight-bold">Subtotal</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText font-weight-bold">USD$ {{ subtotal.toFixed(2) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 70">
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">Tax (Chile {{ tax }}%)</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">USD$ {{ parseFloat(amount * tax / 100 ).toFixed(2) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 70">
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">Tips</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">USD$ {{ parseFloat(amount * tips / 100 ).toFixed(2) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 70">
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">Charity</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">USD$ {{ parseFloat(amount * charity / 100 ).toFixed(2) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="pa-0">
                      <div class="TotalText">Total</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="TotalText">USD$ {{ total.toFixed(2) }}</div>
                      <div class="CryptoTotalText d-inline-flex" v-if="$vuetify.breakpoint.height > 670 || panelHeight > 50">
                        <span class="mr-2" v-if="$vuetify.breakpoint.width > 960">*</span> <div class="scroller">
                          <span>
                            APTA$ 0.05<br/>
                            BTC$ 0.0026<br/>
                            ETH$ 0.005<br/>
                            XRP$ 0.000085<br/>
                          </span>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5" v-if="$vuetify.breakpoint.width > 960">
                    <v-col>
                      <div class="CryptoTotalText font-italic">* Price in others Currencies</div>
                    </v-col>
                  </v-row>
                  <v-row v-if="panelHeight > 40">
                    <v-col>
                      <div class="Separator"></div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>
          </v-row>
          <v-row dense v-if="panelHeight > 85">
            <v-col align-self="center" align="center">
              <v-btn color="#1D4770" dark x-large class="rounded-lg">Cancel</v-btn>
            </v-col>
          </v-row>
          <v-row dense v-if="panelHeight > 40">
            <v-col align-self="center" align="center">
              <v-img v-if="pos" :class="{ 'my-5': panelHeight > 30 }" max-width="300px" width="80%" max-height="30px" contain :src="require('@/assets/p4g.svg')" style="filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));" />
            </v-col>
          </v-row>
          <v-row dense v-if="panelHeight > 90">
            <v-col align-self="center" align="center">
              <v-btn text class="mx-2" x-small @click="terms = !terms">Terms</v-btn>
              <v-btn text class="mx-2" x-small @click="privacy = !privacy">Privacy</v-btn>
            </v-col>
          </v-row>
        </v-container>
        <div v-if="$vuetify.breakpoint.width < 960" class="Arrow pulsate-bck" align="center" ref="arrow" @click="changePanel"><v-icon v-if="panelHeight <= 50">mdi-chevron-double-down</v-icon><v-icon v-if="panelHeight > 50">mdi-chevron-double-up</v-icon></div>
      </div>
      <div :class="{ Lateral: true, 'slide-in-right': $vuetify.breakpoint.width > 960, 'slide-in-bottom': $vuetify.breakpoint.width <= 960, 'slide-out-right': finish }">
        <v-container fluid :class=" { 'fill-height': true, 'py-10': $vuetify.breakpoint.height > 800, 'pt-10': $vuetify.breakpoint.height <= 800 }">
          <v-row v-if="enabled.fastPayment">
            <v-col align-self="center" align="center">
              <div v-if="step !== 5">
                <v-btn class="mx-2"><v-img :src="require('@/assets/i4g.png')" height="32px" :width="$vuetify.breakpoint.width < 600 ? '50px' : '80px'" contain></v-img></v-btn>
                <v-btn class="mx-2"><v-img :src="require('@/assets/gpay.png')" height="32px" :width="$vuetify.breakpoint.width < 600 ? '50px' : '80px'" contain></v-img></v-btn>
                <v-btn class="mx-2"><v-img :src="require('@/assets/apay.png')" height="32px" :width="$vuetify.breakpoint.width < 600 ? '50px' : '80px'" contain></v-img></v-btn>
              </div>
              <div v-if="step !== 5 && $vuetify.breakpoint.height > 900" class="mt-5 FastPayment">Fast Payment</div>
              <div class="SeparatorDark mt-2"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div v-if="step === 1" class="fade-in-fwd">
                <div class="SectionTitle mt-2">Contributions</div>
                <v-container fluid>
                  <v-row v-if="enabled.tips">
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div class="SectionTitle mt-2">Tips</div>
                        <div align="center">
                          <v-slider
                            dark
                            v-model="tips"
                            ticks="always"
                            :max="20"
                            :min="0"
                            thumb-label="always"
                            style="width: 80%; min-width: 250px;"
                            hide-details
                          >
                            <template v-slot:thumb-label>
                              {{ tips }} %
                            </template>
                          </v-slider>
                          <small class="Description mt-1">Your tip will go directly to the seller</small>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="enabled.charity">
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div class="SectionTitle mb-2">Charity</div>
                        <div align="center">
                          <v-slider
                            dark
                            v-model="charity"
                            ticks="always"
                            :max="20"
                            :min="0"
                            thumb-label="always"
                            style="width: 80%; min-width: 250px;"
                            hide-details
                          >
                            <template v-slot:thumb-label>
                              {{ charity }} %
                            </template>
                          </v-slider>
                          <small class="Description mt-1">Your donation goes directly to the organization sponsored by the seller</small>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn class="mt-5 rounded-lg" x-large @click="step++">Continue</v-btn>
              </div>
              <div v-if="step === 2" class="fade-in-fwd">
                <div class="SectionTitle">Shipping Information</div>
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div align="center" class="my-2">
                          <v-text-field dark outlined label="Name" dense style="width: 90%;"></v-text-field>
                          <div style="width: 90%;" class="d-inline-flex" v-if="$vuetify.breakpoint.width >= 600">
                            <v-text-field class="mr-2" dark outlined label="Email" type="email" dense style="width: 60%;"></v-text-field>
                            <v-text-field class="ml-2" dark outlined label="Phone" type="phone" dense style="width: 40%;"></v-text-field>
                          </div>
                          <div style="width: 90%;" v-if="$vuetify.breakpoint.width < 600">
                            <v-text-field class="" dark outlined label="Email" type="email" dense></v-text-field>
                            <v-text-field class="" dark outlined label="Phone" type="phone" dense></v-text-field>
                          </div>
                          <div style="width: 90%;" class="d-inline-flex" v-if="$vuetify.breakpoint.width >= 600">
                            <v-text-field class="mr-2" dark outlined label="Address" dense style="width: 70%;"></v-text-field>
                            <v-text-field class="ml-2" dark outlined label="City" dense style="width: 30%;"></v-text-field>
                          </div>
                          <div style="width: 90%;" v-if="$vuetify.breakpoint.width < 600">
                            <v-text-field class="" dark outlined label="Address" dense></v-text-field>
                            <v-text-field class="" dark outlined label="City" dense></v-text-field>
                          </div>
                          <div style="width: 90%;" class="d-inline-flex">
                            <v-text-field class="mr-2" dark outlined label="Country" dense style="width: 50%;"></v-text-field>
                            <v-text-field class="ml-2" dark outlined label="ZIP" dense style="width: 50%;"></v-text-field>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn class="mt-5 mx-2 rounded-lg" dark @click="step--">Back</v-btn>
                <v-btn class="mt-5 mx-2 rounded-lg" x-large @click="step++">Continue</v-btn>
              </div>
              <div v-if="step === 3" class="fade-in-fwd">
                <div class="SectionTitle">Payment Methods</div>
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">

                        <div align="center">
                          <v-container fluid>
                            <v-row>
                              <v-col v-for="(item, index) in paymentMethodsSupported.filter(item => item.pos === pos || item.all === true)" v-bind:key="index">
                                <v-btn @click="payWith(item.key)" class="mx-2" height="64"><v-img :src="item.logo" height="32px" width="80px" contain></v-img></v-btn>
                              </v-col>
                              <v-col v-for="(item, index) in Array($vuetify.breakpoint.width < 960 ? $vuetify.breakpoint.width < 600 ? 2 : 4 : 12)" v-bind:key="'fill' + index">
                                <div style="width: 112px" class="mx-2"></div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn class="mt-5 mx-2 rounded-lg" dark @click="step--">Back</v-btn>
              </div>
              <div v-if="step === 4" class="fade-in-fwd">
                <div class="SectionTitle">Payment with {{ paymentType }}</div>
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div align="center">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-progress-linear indeterminate height="10" color="white" v-if="!qr"></v-progress-linear>
                                <v-img @click="step++" :src="qr" max-height="300px" max-width="300px" contain class="rounded-xl" v-show="qr" />
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn class="mt-5 mx-2 rounded-lg" dark @click="step--">Back</v-btn>
              </div>
              <div v-if="step === 5" class="fade-in-fwd">
                <v-btn v-if="complete && $vuetify.breakpoint.height < 900 && !pos" class="mt-5 mx-2 rounded-lg" x-large @click="step--">Go back to Site</v-btn>
                <v-btn v-if="complete && $vuetify.breakpoint.height < 900 && pos" class="mt-5 mx-2 rounded-lg" x-large @click="step--">New Payment</v-btn>
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div align="center">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <div v-if="!complete" class="my-16">
                                  <v-progress-linear indeterminate height="10" color="white" v-if="!complete" class="mt-16">
                                  </v-progress-linear>
                                  <span class="white--text mb-16">Processing</span>
                                </div>
                                <div v-if="complete" class="fade-in-fwd">
                                  <div v-if="warning">
                                    <div class="text-h3 white--text">Payment Problem</div>
                                    <div :class="{ 'my-10': $vuetify.breakpoint.height > 900 }">
                                      <WarningIcon />
                                    </div>
                                    <div class="Description mb-2 text-h6">There is a problem processing payments, please try again later</div>
                                  </div>
                                  <div v-if="fail">
                                    <div class="text-h3 white--text">Payment Failed</div>
                                    <div :class="{ 'my-10': $vuetify.breakpoint.height > 900 }">
                                      <FailIcon />
                                    </div>
                                    <div class="Description mb-2 text-h6">Your transaction was rejected by the financial institution</div>
                                  </div>
                                  <div v-if="success">
                                    <div class="text-h3 white--text">Payment Success</div>
                                    <div :class="{ 'my-10': $vuetify.breakpoint.height > 900 }">
                                      <SuccessIcon />
                                    </div>
                                    <div v-if="pos">
                                      <div class="Description mb-2 text-h6">You can get a copy when you print your voucher</div>
                                      <v-btn class="rounded-lg">Print Voucher</v-btn>
                                    </div>
                                    <div v-if="!pos">
                                      <div class="Description mb-2 text-h6">You can get a copy of your transaction through the voucher</div>
                                      <v-text-field dark outlined label="Email" dense style="width: 50%; min-width: 200px"></v-text-field>
                                      <v-btn class="rounded-lg">Send Voucher to Email</v-btn>
                                      <div class="my-5 white--text"> <div class="SeparatorShortDark d-inline-flex"></div> or <div class="SeparatorShortDark d-inline-flex"></div></div>
                                      <v-btn class="mb-10 rounded-lg">Download Voucher</v-btn>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn v-if="complete && $vuetify.breakpoint.height >= 900" class="mt-5 mx-2 rounded-lg" x-large @click="step--">Go back to Site</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div class="SeparatorDark mb-5"></div>
              <v-img v-if="!pos" max-width="150px" :src="require('@/assets/empowered.svg')"  />
              <v-img v-if="pos" max-width="150px" :src="require('@/assets/p4g.svg')"  />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="terms" class="Terms pa-5" align="center">
        <div class="SectionTitle black--text">Terms</div>
        <div align="left" class="pa-5">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies porta suscipit. Pellentesque in tellus ac metus imperdiet egestas ut vel odio. Morbi congue leo lorem, eget vehicula enim auctor ut. Suspendisse id justo cursus risus egestas tincidunt. In gravida commodo dolor, at ornare orci lacinia a. Ut maximus sem vitae ante egestas hendrerit. Nam accumsan metus eget tincidunt tempor. Sed bibendum aliquet ante vel aliquam. Aliquam volutpat ante et lorem consectetur, placerat bibendum metus dignissim. Aenean dignissim dui ornare, porttitor ipsum eget, volutpat felis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam eu ligula rutrum erat ornare elementum eu quis elit. Etiam leo lectus, fringilla ac leo ut, gravida porta odio. Maecenas quis dapibus arcu, in molestie neque. Sed pellentesque, dolor quis mattis accumsan, velit enim bibendum turpis, at mattis urna elit tempus justo. Phasellus quis dolor nisl.
          </p><p>
          Fusce vel dolor ut enim feugiat luctus. Curabitur lacus ex, pulvinar at vulputate fermentum, fermentum rhoncus arcu. Praesent consectetur tortor metus, eget porta lacus elementum sit amet. Quisque nec finibus lectus. Nunc rutrum felis ac laoreet faucibus. Nulla quis volutpat sem. Pellentesque ornare mi ac sem tempor tristique et vel orci. Curabitur tempor placerat urna, vel fringilla mi. Vestibulum in leo id justo congue mattis. Fusce faucibus blandit est eget blandit. Nam vitae nibh euismod, aliquam erat et, vehicula sapien. Suspendisse potenti. Donec ac erat auctor, viverra turpis eleifend, cursus dui. Curabitur sit amet pulvinar enim, et pulvinar ante.
        </p><p>
          Integer faucibus dapibus eros at efficitur. Ut tempus scelerisque sem ac fringilla. Cras eget aliquam quam. Donec vel metus libero. Suspendisse ut posuere metus. Vivamus hendrerit leo enim, at elementum tellus dignissim eget. Vestibulum ut maximus lacus. Nunc efficitur, quam eu accumsan varius, leo velit fringilla nisl, vel pellentesque purus dolor auctor dui. Vestibulum nec libero convallis, mollis arcu molestie, hendrerit odio. Donec eget sagittis dui. Aenean at mattis nibh, non egestas urna. Pellentesque eu convallis nulla.
        </p><p>
          Ut fermentum eu orci id tincidunt. Quisque tincidunt nisi ut lectus lacinia lacinia. Sed ornare magna sed velit ullamcorper blandit. Integer felis sapien, pellentesque quis turpis eget, faucibus blandit felis. Maecenas quis magna volutpat, vulputate velit non, vehicula ex. Vivamus in accumsan urna. Fusce non nisl sagittis, pellentesque tellus vel, ornare quam. Donec a velit cursus, pharetra mi in, egestas nulla. Etiam maximus, ex ut sollicitudin cursus, augue metus luctus felis, id mattis nibh libero sagittis purus. Mauris suscipit lacus in lectus finibus ultricies at quis sem. Fusce at faucibus enim. Nullam eget cursus mauris, eleifend dictum quam. Donec venenatis at mauris sed egestas.
        </p><p>
          Phasellus efficitur nibh rutrum libero rhoncus, sed lobortis justo feugiat. Fusce tincidunt vitae turpis at tempor. Nam sed lectus diam. Morbi vel aliquet arcu, nec bibendum nibh. Nulla hendrerit vel quam blandit cursus. Aenean tincidunt neque fermentum neque tempus facilisis. Proin pharetra condimentum blandit. Aenean ultrices felis ante.
        </p>
        </div>
        <v-btn color="#1D4770" dark x-large class="rounded-lg" @click="terms = !terms">Close</v-btn>
      </div>
      <div v-if="privacy" class="Privacy pa-5" align="center">
        <div class="SectionTitle black--text">Privacy</div>
        <div align="left" class="pa-5">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies porta suscipit. Pellentesque in tellus ac metus imperdiet egestas ut vel odio. Morbi congue leo lorem, eget vehicula enim auctor ut. Suspendisse id justo cursus risus egestas tincidunt. In gravida commodo dolor, at ornare orci lacinia a. Ut maximus sem vitae ante egestas hendrerit. Nam accumsan metus eget tincidunt tempor. Sed bibendum aliquet ante vel aliquam. Aliquam volutpat ante et lorem consectetur, placerat bibendum metus dignissim. Aenean dignissim dui ornare, porttitor ipsum eget, volutpat felis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam eu ligula rutrum erat ornare elementum eu quis elit. Etiam leo lectus, fringilla ac leo ut, gravida porta odio. Maecenas quis dapibus arcu, in molestie neque. Sed pellentesque, dolor quis mattis accumsan, velit enim bibendum turpis, at mattis urna elit tempus justo. Phasellus quis dolor nisl.
          </p><p>
          Fusce vel dolor ut enim feugiat luctus. Curabitur lacus ex, pulvinar at vulputate fermentum, fermentum rhoncus arcu. Praesent consectetur tortor metus, eget porta lacus elementum sit amet. Quisque nec finibus lectus. Nunc rutrum felis ac laoreet faucibus. Nulla quis volutpat sem. Pellentesque ornare mi ac sem tempor tristique et vel orci. Curabitur tempor placerat urna, vel fringilla mi. Vestibulum in leo id justo congue mattis. Fusce faucibus blandit est eget blandit. Nam vitae nibh euismod, aliquam erat et, vehicula sapien. Suspendisse potenti. Donec ac erat auctor, viverra turpis eleifend, cursus dui. Curabitur sit amet pulvinar enim, et pulvinar ante.
        </p><p>
          Integer faucibus dapibus eros at efficitur. Ut tempus scelerisque sem ac fringilla. Cras eget aliquam quam. Donec vel metus libero. Suspendisse ut posuere metus. Vivamus hendrerit leo enim, at elementum tellus dignissim eget. Vestibulum ut maximus lacus. Nunc efficitur, quam eu accumsan varius, leo velit fringilla nisl, vel pellentesque purus dolor auctor dui. Vestibulum nec libero convallis, mollis arcu molestie, hendrerit odio. Donec eget sagittis dui. Aenean at mattis nibh, non egestas urna. Pellentesque eu convallis nulla.
        </p><p>
          Ut fermentum eu orci id tincidunt. Quisque tincidunt nisi ut lectus lacinia lacinia. Sed ornare magna sed velit ullamcorper blandit. Integer felis sapien, pellentesque quis turpis eget, faucibus blandit felis. Maecenas quis magna volutpat, vulputate velit non, vehicula ex. Vivamus in accumsan urna. Fusce non nisl sagittis, pellentesque tellus vel, ornare quam. Donec a velit cursus, pharetra mi in, egestas nulla. Etiam maximus, ex ut sollicitudin cursus, augue metus luctus felis, id mattis nibh libero sagittis purus. Mauris suscipit lacus in lectus finibus ultricies at quis sem. Fusce at faucibus enim. Nullam eget cursus mauris, eleifend dictum quam. Donec venenatis at mauris sed egestas.
        </p><p>
          Phasellus efficitur nibh rutrum libero rhoncus, sed lobortis justo feugiat. Fusce tincidunt vitae turpis at tempor. Nam sed lectus diam. Morbi vel aliquet arcu, nec bibendum nibh. Nulla hendrerit vel quam blandit cursus. Aenean tincidunt neque fermentum neque tempus facilisis. Proin pharetra condimentum blandit. Aenean ultrices felis ante.
        </p>
        </div>
        <v-btn color="#1D4770" dark x-large class="rounded-lg" @click="privacy = !privacy">Close</v-btn>
      </div>
    </div>
</template>

<script>
import './_HomeView.scss'
import common from '@/mixins/common'
import SuccessIcon from '@/components/Icons/SuccessIcon/SuccessIcon'
import FailIcon from '@/components/Icons/FailIcon/FailIcon'
import WarningIcon from '@/components/Icons/WarningIcon/WarningIcon'
export default {
  name: 'HomeView',
  components: { WarningIcon, FailIcon, SuccessIcon },
  data () {
    return {
      terms: false,
      privacy: false,
      pos: false,
      enabled: {
        shipping: true,
        fastPayment: true,
        tips: true,
        charity: true
      },
      finish: false,
      amount: 10,
      step: 1,
      tips: 10,
      tax: 19,
      fee: 1.5,
      charity: 5,
      qr: null,
      paymentType: '',
      complete: false,
      success: true,
      fail: false,
      warning: false,
      panelHeight: 95,
      paymentMethodsSupported: [
        {
          key: 'p4g',
          name: 'Pay4Good',
          logo: require('@/assets/_p4g.svg'),
          pos: true,
          all: false
        },
        {
          key: 'i4g',
          name: 'ID4Good',
          logo: require('@/assets/i4g.png'),
          pos: true,
          all: true
        },
        {
          key: 'gpay',
          name: 'Google Pay',
          logo: require('@/assets/gpay.png'),
          pos: false,
          all: false
        },
        {
          key: 'apay',
          name: 'Apple Pay',
          logo: require('@/assets/apay.png'),
          pos: false,
          all: false
        },
        {
          key: 'alipay',
          name: 'AliPay',
          logo: require('@/assets/alipay.png'),
          pos: false,
          all: false
        },
        {
          key: 'btc',
          name: 'Bitcoin',
          logo: require('@/assets/btc.svg'),
          pos: true,
          all: true
        },
        {
          key: 'ln',
          name: 'Lightning',
          logo: require('@/assets/lightning.svg'),
          pos: true,
          all: true
        },
        {
          key: 'wc',
          name: 'Wallet Connect (Ethereum)',
          logo: require('@/assets/wc.svg'),
          pos: true,
          all: true
        },
        {
          key: 'coopcenral',
          name: 'Coop Central',
          logo: require('@/assets/coopcentral.svg'),
          pos: true,
          all: true
        },
        {
          key: 'cc',
          name: 'Credit Card',
          logo: require('@/assets/creditcard.png'),
          pos: false,
          all: false
        }
      ]
    }
  },
  mounted () {
    if (this.pos) {
      this.enabled.shipping = false
      this.enabled.fastPayment = false
    }
    if (this.$vuetify.breakpoint.width < 960) {
      setTimeout(() => {
        this.panelHeight = 18
        this.completePanel()
      }, 2000)
    }
    if (!this.enabled.tips) {
      this.tips = 0
    }
    if (!this.enabled.charity) {
      this.charity = 0
    }
    if (!this.enabled.tips && !this.enabled.charity) {
      this.step++
    }
  },
  watch: {
    step: {
      deep: true,
      handler: function (value) {
        document.body.scrollTo(0, 0)
        if (value === 2) {
          if (!this.enabled.shipping) {
            this.step++
          }
        }
        if (value === 5) {
          setTimeout(() => { this.complete = true }, 2000)
        }
      }
    }
  },
  mixins: [common],
  computed: {
    subtotal () {
      return parseFloat(parseFloat(this.amount) + parseFloat(this.amount * this.fee / 100))
    },
    total () {
      return parseFloat(this.subtotal + this.subtotal * this.tax / 100 + this.subtotal * this.tips / 100 + this.subtotal * this.charity / 100)
    }
  },
  methods: {
    startPanel () {
      console.log('start')
      document.body.style.overflow = 'hidden'
    },
    completePanel () {
      document.body.style.overflow = 'scroll'
      console.log('complete')
      if (this.panelHeight < 50) {
        this.panelHeight = 18
      }
      if (this.panelHeight >= 50) {
        this.panelHeight = 95
      }
      this.$refs.default.style.height = this.panelHeight + '%'
    },
    changePanel () {
      if (this.panelHeight >= 50) {
        this.panelHeight = 30
      } else {
        this.panelHeight = 95
      }
      this.completePanel()
    },
    movePanel (event) {
      let percent = parseFloat(event.targetTouches[0].clientY / window.innerHeight).toFixed(2) * 100
      if (percent > 95) {
        percent = 95
      }
      if (percent < 18) {
        percent = 18
      }
      this.panelHeight = percent
      this.$refs.default.style.height = percent + '%'
    },
    async payWith (method) {
      this.qr = null
      this.step++
      if (method === 'i4g') {
        const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
        this.paymentType = paymentMethod.name
        this.qr = await this.generateCodeQR('demo_code_payment', paymentMethod.logo, 300, 300, '#10395D', '#10395D', '#FFFFFF')
      }
    }
  }
}
</script>
