<template>
    <div class="TransactionsView">
      <QuickMenuComponent />
      <v-container>
        <v-row>
          <v-col align-self="center" align="center">
            <QuickMenuTransactionsComponent />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="SectionTitle">
              Transactions
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="d-flex">
              <v-text-field v-model="search" prepend-icon="mdi-cloud-search-outline" label="Search" filled outlined></v-text-field>
              <div class="Balance">
                <div class="BalanceTitle">Filters</div>
                <v-btn class="mx-2" outlined small>All</v-btn>
                <v-btn class="mx-2" outlined small>Success</v-btn>
                <v-btn class="mx-2" outlined small>Failed</v-btn>
                <v-btn class="mx-2" outlined small>Refund</v-btn>
              </div>
            </div>
            <v-data-table
              :loading="loading"
              :headers="transactionHeaders"
              :items="transactionData"
              :search.sync="search"
              :expanded.sync="expanded"
              item-key="id"
              :single-expand="true"
              class="Table"
              style="background-color: transparent"
              show-expand
              show-select
              v-model="selected"
              dark
            >
              <template v-slot:[`item.method`]="{ item }">
                <div class="d-flex">
                  <v-img class="mx-2" :src="(paymentMethodsSupported.find(m => m.key === item.method)).logo" contain max-height="20px" max-width="30px"></v-img> {{ (paymentMethodsSupported.find(m => m.key === item.method)).name }}
                </div>
              </template>
              <template v-slot:[`item.total`]="{ item }">
                <b>USD$ {{ item.total }}</b>
              </template>
              <template v-slot:[`item.amount`]="{ item }">
                USD$ {{ item.amount }}
              </template>
              <template v-slot:[`item.fee`]="{ item }">
                USD$ {{ item.fee }}
              </template>
              <template v-slot:[`item.network`]="{ item }">
                USD$ {{ item.network }}
              </template>
              <template v-slot:[`item.vat`]="{ item }">
                USD$ {{ item.vat }}
              </template>
              <template v-slot:[`item.tips`]="{ item }">
                USD$ {{ item.tips }}
              </template>
              <template v-slot:[`item.charity`]="{ item }">
                USD$ {{ item.charity }}
              </template>
              <template v-slot:[`item.state`]="{ item }">
                <v-chip small :color="getStateColor(item.state)">
                  {{ item.state }}
                </v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="primary" v-if="item.method === 'cc'" :disabled="item.state !== 'complete'" tile fab x-small class="mx-2" :light="true" @click="item.id"><v-icon>mdi-credit-card-refund</v-icon></v-btn>
                <v-btn color="primary" v-if="item.method !== 'cc'" :disabled="item.state !== 'complete'" tile fab x-small class="mx-2" :light="true" @click="item.id"><v-icon>mdi-wallet-giftcard</v-icon></v-btn>
                <v-btn color="success" tile fab x-small class="mx-2" :disabled="item.state !== 'complete'" :light="true" @click="item.id"><v-icon>mdi-receipt-text-check</v-icon></v-btn>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-container fluid>
                    <v-row dense>
                      <v-col>
                        <v-stepper :value="getStep(item.state)" style="background-color: transparent" :light="true" elevation="0" alt-labels>
                          <v-stepper-header>
                            <v-stepper-step step="1" :complete="getStep(item.state) >= 1">
                              Created
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step step="2" :complete="getStep(item.state) >= 2">
                              Waiting
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step step="3" :complete="getStep(item.state) >= 3">
                              Pending
                            </v-stepper-step>
                            <v-divider></v-divider>
                            <v-stepper-step color="success" step="4" :complete="getStep(item.state) >= 4" v-if="item.state === 'complete' || item.state === 'refund' || getStep(item.state) < 4">
                              Complete
                            </v-stepper-step>
                            <v-stepper-step :rules="[() => false]" step="4" :complete="getStep(item.state) >= 4" v-if="item.state === 'failed'">
                              Failed
                            </v-stepper-step>
                            <v-divider v-if="item.state === 'refund'"></v-divider>
                            <v-stepper-step complete-icon="mdi-credit-card-refund" color="cyan" step="5" :complete="getStep(item.state) >= 5" v-if="item.state === 'refund'">
                              Refund
                            </v-stepper-step>
                          </v-stepper-header>
                        </v-stepper>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <div class="SectionSubtitle">Payment Information</div>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col align-self="center" align="center" cols="3">
                        <v-img class="mx-2" :src="(paymentMethodsSupported.find(m => m.key === item.method)).logo" contain max-height="120px" max-width="120px"></v-img>
                        <div class="Description">{{ (paymentMethodsSupported.find(m => m.key === item.method)).name }}</div>
                      </v-col>
                      <v-col align="left">
                        <v-container fluid>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Datetime:</v-col>
                            <v-col class="DetailContent">{{ item.datetime }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">ID:</v-col>
                            <v-col class="DetailContent">{{ item.id }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Payment Method:</v-col>
                            <v-col class="DetailContent">{{ (paymentMethodsSupported.find(m => m.key === item.method)).name }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Country:</v-col>
                            <v-col class="DetailContent">{{ item.country }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">IP:</v-col>
                            <v-col class="DetailContent">{{ item.ip }}</v-col>
                          </v-row>
                          <v-row dense v-if="item.method === 'cc'">
                            <v-col class="DetailTitle" align-self="center" align="right">Card:</v-col>
                            <v-col class="DetailContent" align-self="center">
                              <div class="d-flex" style="align-items: center;">
                                <v-img class="mr-2" :src="require('@/assets/cards/visa-color.svg')" max-width="32px" max-height="32px" contain></v-img>•••• 1234
                              </div>
                            </v-col>
                          </v-row>
                          <v-row dense v-if="item.method === 'i4g'">
                            <v-col class="DetailTitle" align-self="center" align="right">Identify:</v-col>
                            <v-col class="DetailContent" align-self="center">
                              <div class="d-flex" style="align-items: center;">
                                <v-img class="mr-2" :src="require('@/assets/i4g.svg')" max-width="32px" max-height="32px" contain></v-img>••••ndvn>2298475334o9uvn
                              </div>
                            </v-col>
                          </v-row>
                          <v-row dense v-if="item.method === 'btc'">
                            <v-col class="DetailTitle" align-self="center" align="right">Address:</v-col>
                            <v-col class="DetailContent" align-self="center">
                              <div class="d-flex" style="align-items: center;">
                                <v-img class="mr-2" :src="require('@/assets/btc.svg')" max-width="32px" max-height="32px" contain></v-img>••••f2493p83kkfjhx0wlhs
                              </div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col align="left">
                        <v-container fluid>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Amount:</v-col>
                            <v-col class="DetailContent">USD$ {{ item.amount }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Service Fee:</v-col>
                            <v-col class="DetailContent">USD$ {{ item.fee }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Network Fee:</v-col>
                            <v-col class="DetailContent">USD$ {{ item.network }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Subtotal:</v-col>
                            <v-col class="DetailContent">USD$ {{ item.amount + item.fee + item.network }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">VAT:</v-col>
                            <v-col class="DetailContent">USD$ {{ item.vat }} ({{ (countries.find(country => country.name === item.country)).flag }} {{ item.vatPercent }}% )</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Tips:</v-col>
                            <v-col class="DetailContent">USD$ {{ item.tips }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Charity:</v-col>
                            <v-col class="DetailContent">USD$ {{ item.charity }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Total:</v-col>
                            <v-col class="DetailContent"><b>USD$ {{ item.total }}</b></v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="item.shipping">
                      <v-col>
                        <div class="SectionSubtitle">Shipping Information</div>
                      </v-col>
                    </v-row>
                    <v-row dense v-if="item.shipping">
                      <v-col align-self="center" align="center" cols="3">
                        <v-img class="mx-2" :src="require('@/assets/shipping.png')" contain max-height="120px" max-width="120px"></v-img>
                      </v-col>
                      <v-col align="left">
                        <v-container fluid>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Name:</v-col>
                            <v-col class="DetailContent">{{ item.shipping.name }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">E-mail:</v-col>
                            <v-col class="DetailContent">{{ item.shipping.email }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Phone:</v-col>
                            <v-col class="DetailContent">{{ item.shipping.phone }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Address:</v-col>
                            <v-col class="DetailContent">{{ item.shipping.address }}</v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                      <v-col align="left">
                        <v-container fluid>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">City:</v-col>
                            <v-col class="DetailContent">{{ item.shipping.city }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">State:</v-col>
                            <v-col class="DetailContent">{{ item.shipping.state }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">Country:</v-col>
                            <v-col class="DetailContent">{{ item.shipping.country }}</v-col>
                          </v-row>
                          <v-row dense>
                            <v-col class="DetailTitle" align="right">ZIP:</v-col>
                            <v-col class="DetailContent">{{ item.shipping.zip }}</v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-container>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_TransactionsView.scss'
import QuickMenuComponent from '@/components/QuickMenuComponent/QuickMenuComponent'
import QuickMenuTransactionsComponent from '@/components/QuickMenuTransactionsComponent/QuickMenuTransactionsComponent'
import { Country } from 'country-state-city'

export default {
  name: 'TransactionsView',
  components: { QuickMenuTransactionsComponent, QuickMenuComponent },
  data () {
    return {
      search: '',
      loading: true,
      selected: {},
      expanded: [],
      countries: [],
      paymentMethodsSupported: [
        {
          key: 'p4g',
          name: 'Pay4Good',
          logo: require('@/assets/_p4g.svg'),
          pos: true,
          all: false
        },
        {
          key: 'i4g',
          name: 'ID4Good',
          logo: require('@/assets/i4g.png'),
          pos: true,
          all: true
        },
        {
          key: 'gpay',
          name: 'Google Pay',
          logo: require('@/assets/gpay.png'),
          pos: false,
          all: false
        },
        {
          key: 'apay',
          name: 'Apple Pay',
          logo: require('@/assets/apay.png'),
          pos: false,
          all: false
        },
        {
          key: 'alipay',
          name: 'AliPay',
          logo: require('@/assets/alipay.png'),
          pos: false,
          all: false
        },
        {
          key: 'btc',
          name: 'Bitcoin',
          logo: require('@/assets/btc.svg'),
          pos: true,
          all: true
        },
        {
          key: 'ln',
          name: 'Lightning',
          logo: require('@/assets/lightning.svg'),
          pos: true,
          all: true
        },
        {
          key: 'wc',
          name: 'Wallet Connect (Ethereum)',
          logo: require('@/assets/wc.svg'),
          pos: true,
          all: true
        },
        {
          key: 'coopcenral',
          name: 'Coop Central',
          logo: require('@/assets/coopcentral.svg'),
          pos: true,
          all: true
        },
        {
          key: 'cc',
          name: 'Credit Card',
          logo: require('@/assets/creditcard.png'),
          pos: false,
          all: false
        }
      ],
      transactionHeaders: [
        {
          text: 'Datetime',
          align: 'start',
          value: 'datetime'
        },
        { text: 'Payment Method', value: 'method' },
        { text: 'Total', value: 'total' },
        { text: 'Amount', value: 'amount' },
        { text: 'Fee', value: 'fee' },
        { text: 'Network Fee', value: 'network' },
        { text: 'VAT', value: 'vat' },
        { text: 'Tips', value: 'tips' },
        { text: 'Charity', value: 'charity' },
        { text: 'State', value: 'state' },
        { text: '', value: 'actions' }
      ],
      transactionData: [
        {
          id: '-230924490jklfdj03',
          datetime: 'Jun 11 2022 15:48:00',
          method: 'cc',
          total: 50,
          amount: 30,
          fee: 5,
          network: 0.1,
          vat: 10,
          vatPercent: 19,
          tips: 5,
          charity: 5,
          ip: '127.0.0.1',
          country: 'Chile',
          state: 'complete',
          shipping: {
            name: 'Felix Jara',
            email: 'felix@bloqs4good.com',
            phone: '+56992910910',
            address: 'My House #3456',
            city: 'Valdivia',
            state: 'Los rios',
            country: 'Chile',
            zip: '5090000'
          }
        },
        {
          id: '-230924490jklfdj04',
          datetime: 'Jun 11 2022 15:48:05',
          method: 'i4g',
          total: 85,
          amount: 40,
          fee: 5,
          network: 0.1,
          vat: 15,
          vatPercent: 20,
          tips: 15,
          charity: 15,
          ip: '127.0.0.1',
          country: 'United States',
          state: 'pending',
          shipping: {
            name: 'Felix Jara',
            email: 'felix@bloqs4good.com',
            phone: '+56992910910',
            address: 'My House #3456',
            city: 'Valdivia',
            state: 'Los rios',
            country: 'Chile',
            zip: '5090000'
          }
        },
        {
          id: '-230924490jklfdj05',
          datetime: 'Jun 11 2022 15:48:10',
          method: 'btc',
          total: 85,
          amount: 40,
          fee: 5,
          network: 0.1,
          vat: 15,
          vatPercent: 15,
          tips: 15,
          charity: 15,
          ip: '127.0.0.1',
          country: 'Colombia',
          state: 'failed'
        },
        {
          id: '-230924490jklfdj07',
          datetime: 'Jun 11 2022 15:48:20',
          method: 'cc',
          total: 50,
          amount: 30,
          fee: 5,
          network: 0.1,
          vat: 10,
          vatPercent: 6,
          tips: 5,
          charity: 5,
          ip: '127.0.0.1',
          country: 'Canada',
          state: 'refund',
          shipping: {
            name: 'Felix Jara',
            email: 'felix@bloqs4good.com',
            phone: '+56992910910',
            address: 'My House #3456',
            city: 'Valdivia',
            state: 'Los rios',
            country: 'Chile',
            zip: '5090000'
          }
        }
      ]
    }
  },
  mounted () {
    this.countries = Country.getAllCountries()
    this.loading = false
  },
  methods: {
    getStep (state) {
      if (state === 'created') {
        return 1
      }
      if (state === 'complete') {
        return 4
      }
      if (state === 'waiting') {
        return 2
      }
      if (state === 'pending') {
        return 3
      }
      if (state === 'failed') {
        return 4
      }
      if (state === 'refund') {
        return 5
      }
    },
    getStateColor (state) {
      if (state === 'created') {
        return 'grey'
      }
      if (state === 'complete') {
        return 'success'
      }
      if (state === 'waiting') {
        return 'warning'
      }
      if (state === 'pending') {
        return 'warning'
      }
      if (state === 'failed') {
        return 'error'
      }
      if (state === 'refund') {
        return 'cyan'
      }
    }
  }
}
</script>
