<template>
    <div class="PermissionsView">
      <QuickMenuComponent />
      <v-container>
        <v-row>
          <v-col align-self="center" align="center">
            <QuickMenuBusinessComponent />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="SectionTitle">
              Public Information
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="Section">
              <v-text-field prepend-icon="ID" label="ID" filled outlined append-icon="mdi-content-paste"></v-text-field>
              <v-text-field prepend-icon="mdi-description" label="Description" filled outlined></v-text-field>
              <div align="left" class="SectionTitle mt-5">Permissions</div>
              <v-container fluid class="mx-10">
                <v-row>
                  <v-col align-self="" align="center">
                    <div align="left" class="SectionSubtitle">Business</div>
                    <v-switch label="All" hide-details filled outlined></v-switch>
                    <v-switch label="Business View" hide-details filled outlined></v-switch>
                    <v-switch label="Business Public Edit" hide-details filled outlined></v-switch>
                    <v-switch label="Payment View" hide-details filled outlined></v-switch>
                    <v-switch label="Payment Edit" hide-details filled outlined></v-switch>
                    <v-switch label="Payment Withdrawal" hide-details filled outlined></v-switch>
                  </v-col>
                  <v-col align-self="" align="center" class="mx-10">
                    <div align="left" class="SectionSubtitle">Transactions</div>
                    <v-switch label="All" hide-details filled outlined></v-switch>
                    <v-switch label="Transaction View" hide-details filled outlined></v-switch>
                    <v-switch label="Transaction Detail View" hide-details filled outlined></v-switch>
                    <v-switch label="Transaction Refund" hide-details filled outlined></v-switch>
                    <v-switch label="Transaction Voucher" hide-details filled outlined></v-switch>
                    <v-switch label="Balance View" hide-details filled outlined></v-switch>
                  </v-col>
                  <v-col align-self="" align="center" class="mx-10">
                    <div align="left" class="SectionSubtitle">Devices</div>
                    <v-switch label="All" hide-details filled outlined></v-switch>
                    <v-switch label="Device View" hide-details filled outlined></v-switch>
                    <v-switch label="Device Edit" hide-details filled outlined></v-switch>
                    <v-switch label="Device Activated" hide-details filled outlined></v-switch>
                    <v-switch label="Device Blocked" hide-details filled outlined></v-switch>
                    <v-switch label="Device Delete" hide-details filled outlined></v-switch>
                  </v-col>
                  <v-col align-self="" align="center" class="mx-10">
                    <div align="left" class="SectionSubtitle">Finance</div>
                    <v-switch label="All" hide-details filled outlined></v-switch>
                    <v-switch label="Finance View" hide-details filled outlined></v-switch>
                    <v-switch label="Finance Leading" hide-details filled outlined></v-switch>
                    <v-switch label="Finance Investment" hide-details filled outlined></v-switch>
                  </v-col>
                </v-row>
              </v-container>
              <div align="right" class="my-5">
                <v-btn class="px-10" x-large color="success">Add</v-btn>
              </div>
              <div align="left" class="SectionTitle mb-5" @click="confirm = true">Permissions Granted</div>
              <v-data-table
                dense
                :headers="transactionHeaders"
                :items="transactionData"
                item-key="name"
                class="Table"
                style="background-color: transparent"
                dark
              ></v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_PermissionsView.scss'
import QuickMenuBusinessComponent from '@/components/QuickMenuBusinessComponent/QuickMenuBusinessComponent'
import QuickMenuComponent from '@/components/QuickMenuComponent/QuickMenuComponent'

export default {
  name: 'PermissionsView',
  components: { QuickMenuComponent, QuickMenuBusinessComponent },
  data () {
    return {
      tab: null,
      availableAccount: ['Main', 'VAT', 'Tips', 'Charity'],
      coins: ['T4G', 'USDT', 'BUSD', 'USDC'],
      network: ['4GoodNetwork', 'ERC20', 'TRC20'],
      confirm: false,
      transactionHeaders: [
        {
          text: 'ID',
          align: 'start',
          value: 'datetime'
        },
        { text: 'Name', value: 'calories' },
        { text: 'Description', value: 'calories' },
        { text: 'Permissions', value: 'iron' },
        { text: '', value: 'actions' }
      ],
      transactionData: []
    }
  }
}
</script>
