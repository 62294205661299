<template>
    <div class="TipsAndCharityView">
      <QuickMenuComponent />
      <v-container>
        <v-row>
          <v-col align-self="center" align="center">
            <QuickMenuBusinessComponent />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="SectionTitle">
              Tips and Charity
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="Section">
              <v-tabs
                v-model="tab"
                icons-and-text
                centered
                background-color="transparent"
              >
                <v-tabs-slider></v-tabs-slider>
                <v-tab href="#tips">
                  Tips
                  <v-icon>mdi-piggy-bank</v-icon>
                </v-tab>
                <v-tab href="#charity">
                  Charity
                  <v-icon>mdi-hand-coin</v-icon>
                </v-tab>
                <v-tabs-items v-model="tab" style="background-color: transparent" class="mt-10">
                  <v-tab-item value="tips">
                    <div class="Description">
                      You can activate an account to store tips, this activates the tips option in the payment flows.
                    </div>
                    <div align="center">
                      <v-switch label="Active" style="max-width: 100px" v-model="active.tips"></v-switch>
                    </div>
                    <div v-if="active.tips" class="Separator"></div>
                    <div v-if="active.tips" class="slide-in-bottom">
                      <div align="left">
                        <div class="SectionTitle">Current Month</div>
                        <div>
                          <div class="Balance d-inline-flex">
                            <div class="BalanceTitle">Volume</div>
                            USD$ 925.1
                          </div>
                          <div class="Balance d-inline-flex">
                            <div class="BalanceTitle">Previous Month</div>
                            USD$ 825.7
                          </div>
                        </div>
                      </div>
                      <apexChart class="Chart" v-if="!loading" width="100%" height="300px" type="area" :options="chartTipsMonthOptions" :series="monthTipsSeries"></apexChart>
                    </div>
                  </v-tab-item>
                  <v-tab-item value="charity">
                    <div class="Description">
                      You can allocate an additional percentage to charity donations, you can choose some of the registered entities or define a new one.
                    </div>
                    <div align="center">
                      <v-switch label="Active" style="max-width: 100px" v-model="active.charity"></v-switch>
                    </div>
                    <div v-if="active.charity" class="Separator"></div>
                    <div v-if="active.charity" class="slide-in-bottom">
                      <v-autocomplete v-model="charity" :items="charities" prepend-icon="mdi-account-group-outline" label="Charity" filled outlined></v-autocomplete>
                      <div v-if="charity !== 'Custom' && charity !== null">
                        <v-img class="my-5" :src="(charitiesInfo.find(item => item.name === charity)).icon" contain max-height="200px"></v-img>
                        <span class="Description my-5 text-h6">{{ (charitiesInfo.find(item => item.name === charity)).description }}</span>
                      </div>
                      <v-text-field v-if="charity === 'Custom'" prepend-icon="mdi-rename-box" label="Charity Name" v-model="charityName" filled outlined></v-text-field>
                      <div align="right" class="my-5">
                        <v-btn class="px-10" x-large color="success">Save</v-btn>
                      </div>
                    </div>
                    <div v-if="active.charity" class="Separator"></div>
                    <div v-if="active.charity" class="slide-in-bottom">
                      <div align="left">
                        <div class="SectionTitle">Current Month</div>
                        <div>
                          <div class="Balance d-inline-flex">
                            <div class="BalanceTitle">Volume</div>
                            USD$ 925.1
                          </div>
                          <div class="Balance d-inline-flex">
                            <div class="BalanceTitle">Previous Month</div>
                            USD$ 825.7
                          </div>
                        </div>
                      </div>
                      <apexChart class="Chart" v-if="!loading" width="100%" height="300px" type="area" :options="chartCharityMonthOptions" :series="monthCharitySeries"></apexChart>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-tabs>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_TipsAndCharityView.scss'

import QuickMenuComponent from '@/components/QuickMenuComponent/QuickMenuComponent'
import QuickMenuBusinessComponent from '@/components/QuickMenuBusinessComponent/QuickMenuBusinessComponent'
export default {
  name: 'TipsAndCharityView',
  components: { QuickMenuBusinessComponent, QuickMenuComponent },
  data () {
    return {
      active: {
        tips: false,
        charity: false
      },
      tab: null,
      loading: true,
      charity: null,
      charityName: null,
      charities: ['Unicef', 'Care', 'EduCo', 'Custom'],
      charitiesInfo: [
        {
          name: 'Unicef',
          icon: require('@/assets/ong/unicef.png'),
          description: 'UNICEF works in the world’s toughest places to reach the most disadvantaged children and adolescents – and to protect the rights of every child, everywhere. Across more than 190 countries and territories, we do whatever it takes to help children survive, thrive and fulfill their potential, from early childhood through adolescence.'
        },
        {
          name: 'Care',
          icon: require('@/assets/ong/care.png'),
          description: 'CARE International places special focus on working alongside women and girls. Equipped with the proper resources, women and girls have the power to lift whole families and entire communities out of poverty.'
        },
        {
          name: 'EduCo',
          icon: require('@/assets/ong/educo.png'),
          description: 'EduCo is a globally connected team of international education professionals that helps connect international students with a network of universities worldwide.'
        }
      ],
      month: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      chartTipsMonthOptions: {
        colors: ['#c2c017'],
        chart: {
          id: 'monthChart',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: []
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return 'USD$ ' + value
            }
          }
        }
      },
      chartCharityMonthOptions: {
        colors: ['#22c217'],
        chart: {
          id: 'monthChart',
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          categories: []
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return 'USD$ ' + value
            }
          }
        }
      },
      monthTipsSeries: [{
        name: 'Volume',
        data: []
      }],
      monthCharitySeries: [{
        name: 'Volume',
        data: []
      }]
    }
  },
  mounted () {
    this.chartTipsMonthOptions.xaxis.categories = this.generateMonth()
    this.chartCharityMonthOptions.xaxis.categories = this.generateMonth()
    this.monthTipsSeries[0].data = new Array(this.chartTipsMonthOptions.xaxis.categories.length).fill().map(() => parseFloat(500 * Math.random()).toFixed(2))
    this.monthCharitySeries[0].data = new Array(this.chartCharityMonthOptions.xaxis.categories.length).fill().map(() => parseFloat(500 * Math.random()).toFixed(2))
    this.loading = false
  },
  methods: {
    getCurrentMonth () {
      const time = new Date()
      return this.month[time.getMonth()]
    },
    generateMonth () {
      const time = new Date()
      const days = new Date(time.getFullYear(), time.getMonth() + 1, 0).getDate()
      const month = Object.keys(new Array(days).fill(0))
      month.push(month.length.toString())
      month.splice(0, 1)
      console.log(month)
      return month
    }
  }
}
</script>
