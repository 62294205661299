<template>
    <div class="POSView">
      <div ref="default" :class="{ Default: true, 'slide-in-left': $vuetify.breakpoint.width > 720, 'slide-in-top': $vuetify.breakpoint.width <= 720, 'slide-out-left': finish }">
        <v-container fluid class="fill-height">
          <v-row dense>
            <v-col align-self="center" align="center">
              <v-img :src="require('@/assets/p4g_default.svg')" contain max-height="50px" max-width="300px" v-if="$vuetify.breakpoint.width <= 600 || $vuetify.breakpoint.width >= 960" />
              <v-img :src="require('@/assets/p4g_default.svg')" contain max-height="20px" max-width="300px" v-if="$vuetify.breakpoint.width > 600 && $vuetify.breakpoint.width < 960" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center">
              <div align="left" class="PaymentDetail">
                <v-container fluid class="py-0">
                  <v-row dense v-if="panelHeight > 45">
                    <v-col align-self="center" align="center">
                      <div class="PartnerText">{{ merchant }} <v-icon color="green" class="mx-1" size="28">mdi-check-decagram</v-icon></div>
                    </v-col>
                  </v-row>
                  <v-row dense :class="{ 'mb-5': panelHeight > 35, 'mb-1': panelHeight <= 35 }" v-if="panelHeight > 20">
                    <v-col>
                      <div class="Separator"></div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 80">
                    <v-col class="pa-0">
                      <div class="SubtotalText">{{ $t('amount') }}</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText">{{ currency }}$ {{ fixedNumber(amount) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 80">
                    <v-col class="pa-0">
                      <div class="SubtotalText">{{ $t('service fee') }} ({{ fee }}%)</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText">{{ currency }}$ {{ fixedNumber(numericFee) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 80">
                    <v-col class="pa-0">
                      <div class="SubtotalText">{{ $t('network fee') }}</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText">{{ currency }}$ {{ fixedNumber(network) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense :class="{ 'mt-5': panelHeight > 80 }" v-if="panelHeight > 20">
                    <v-col class="pa-0">
                      <div class="SubtotalText font-weight-bold">Subtotal</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalText font-weight-bold">{{ currency }}$ {{ fixedNumber(subtotal) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 20 && tax > 0">
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">{{ taxType.toUpperCase() }} ({{ getCountryFlag(country) }} {{ tax.toFixed(2) }}%)</div>
                      <small v-if="state && panelHeight > 70"> {{ getStateName(this.state) }}</small>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">{{ currency }}$ {{ fixedNumber(numericTax) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 70">
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">{{ $t('tips') }}</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">{{ currency }}$ {{ fixedNumber(numericTips) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense v-if="panelHeight > 70">
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">{{ $t('charity') }}</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="SubtotalNoTaxText">{{ currency }}$ {{ fixedNumber(numericCharity) }}</div>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col class="pa-0">
                      <div class="TotalText">Total</div>
                    </v-col>
                    <v-col class="pa-0">
                      <div class="TotalText">{{ currency }}$ {{ fixedNumber(total) }}</div>
                      <div class="CryptoTotalText d-inline-flex" v-if="$vuetify.breakpoint.height > 670 || panelHeight > 50">
                        <span class="mr-2" v-if="$vuetify.breakpoint.width > 960">*</span> <div class="scroller">
                          <span>
                            <div v-if="getEquivalentCurrency(getCountryCurrency(this.country))">{{ getCountryCurrency(this.country) }}$ ~{{ fixedNumber(parseFloat(getEquivalentCurrency(getCountryCurrency(this.country)) * total)) }}</div>
                            <div>APTA$ ~{{ parseFloat(getEquivalentCurrency('APTA') * total).toFixed(3) }}</div>
                            <div>BTC$ ~{{ parseFloat(getEquivalentCurrency('BTC') * total).toFixed(5) }}</div>
                            <div>ETH$ ~{{ parseFloat(getEquivalentCurrency('ETH') * total).toFixed(4) }}</div>
                          </span>
                      </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5" v-if="$vuetify.breakpoint.width > 960">
                    <v-col>
                      <div class="CryptoTotalText font-italic">* {{ $t('price in others currencies') }}</div>
                    </v-col>
                  </v-row>
                  <v-row v-if="panelHeight > 40">
                    <v-col>
                      <div class="Separator"></div>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-col>
          </v-row>
          <v-row dense v-if="panelHeight > 90">
            <v-col align-self="center" align="center">
              <v-img :class="{ 'my-3': panelHeight > 30 }" max-width="300px" width="80%" max-height="25px" contain :src="require('@/assets/p4g_default.svg')" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center">
              <div v-if="panelHeight > 90">
                <v-btn text class="mx-2" x-small @click="terms = !terms">{{ $t('terms') }}</v-btn>
                <v-btn text class="mx-2" x-small @click="privacy = !privacy">{{ $t('privacy') }}</v-btn>
                <v-btn text class="mx-2" x-small @click="privacy = !privacy">{{ $t('history') }}</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center">
              <div v-if="$vuetify.breakpoint.width < 720" class="Arrow" align="center" ref="arrow" @click="changePanel"><v-icon v-if="panelHeight <= 50">mdi-chevron-double-down</v-icon><v-icon v-if="panelHeight > 50">mdi-chevron-double-up</v-icon></div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div :class="{ Lateral: true, 'slide-in-right': $vuetify.breakpoint.width > 720, 'slide-in-bottom': $vuetify.breakpoint.width <= 720, 'slide-out-right': finish }">
        <v-container fluid :class=" { 'fill-height': true, 'py-10': $vuetify.breakpoint.height > 800, 'pt-10': $vuetify.breakpoint.height <= 800 }" :style="$vuetify.breakpoint.width <= 720 ? 'padding-top: 30% !important' : ''">
          <v-row v-if="enabled.fastPayment">
            <v-col align-self="center" align="center">
              <div v-if="step !== 5">
                <v-btn class="mx-2"><v-img :src="require('@/assets/i4g.png')" height="32px" :width="$vuetify.breakpoint.width < 600 ? '50px' : '80px'" contain></v-img></v-btn>
                <v-btn class="mx-2"><v-img :src="require('@/assets/gpay.png')" height="32px" :width="$vuetify.breakpoint.width < 600 ? '50px' : '80px'" contain></v-img></v-btn>
                <v-btn class="mx-2"><v-img :src="require('@/assets/apay.png')" height="32px" :width="$vuetify.breakpoint.width < 600 ? '50px' : '80px'" contain></v-img></v-btn>
              </div>
              <div v-if="step !== 5 && $vuetify.breakpoint.height > 900" class="mt-5 FastPayment">{{ $('fast payment') }}</div>
              <div class="SeparatorDark mt-2"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div v-if="step === 0" class="fade-in-fwd">
                <div class="SectionAmount"> {{ currency }}$ {{ amount }}</div>
                <div class="Section">
                  <div class="mt-5">
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(1)"><v-icon color="#1D4770" x-large>mdi-numeric-1</v-icon></v-btn>
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(2)"><v-icon color="#1D4770" x-large>mdi-numeric-2</v-icon></v-btn>
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(3)"><v-icon color="#1D4770" x-large>mdi-numeric-3</v-icon></v-btn>
                  </div>
                  <div class="mt-2">
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(4)"><v-icon color="#1D4770" x-large>mdi-numeric-4</v-icon></v-btn>
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(5)"><v-icon color="#1D4770" x-large>mdi-numeric-5</v-icon></v-btn>
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(6)"><v-icon color="#1D4770" x-large>mdi-numeric-6</v-icon></v-btn>
                  </div>
                  <div class="mt-2">
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(7)"><v-icon color="#1D4770" x-large>mdi-numeric-7</v-icon></v-btn>
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(8)"><v-icon color="#1D4770" x-large>mdi-numeric-8</v-icon></v-btn>
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(9)"><v-icon color="#1D4770" x-large>mdi-numeric-9</v-icon></v-btn>
                  </div>
                  <div class="mt-2">
                    <v-btn x-large class="rounded-lg mx-1" :disabled="amount.toString().indexOf('.') > 0" @click="addPoint()"><v-icon color="#1D4770" x-large>mdi-circle-small</v-icon></v-btn>
                    <v-btn x-large class="rounded-lg mx-1" @click="addNumber(0)"><v-icon color="#1D4770" x-large>mdi-numeric-0</v-icon></v-btn>
                    <v-btn x-large class="rounded-lg mx-1" @click="removeNumber()"><v-icon color="#1D4770">mdi-backspace</v-icon></v-btn>
                  </div>
                  <div class="mt-5">
                    <v-btn :disabled="total === 0" x-large class="rounded-lg" color="green" dark @click="pay">{{ $t('pay') }}</v-btn>
                  </div>
                </div>
              </div>
              <div v-if="step === 1" class="fade-in-fwd">
                <div class="SectionTitle mt-2">{{ $t('contributions') }}</div>
                <v-container fluid>
                  <v-row v-if="enabled.tips">
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div class="SectionTitle mt-2">{{ $t('tips') }}</div>
                        <div align="center">
                          <v-slider
                            dark
                            v-model="tips"
                            ticks="always"
                            :max="20"
                            :min="0"
                            thumb-label="always"
                            style="width: 80%; min-width: 250px;"
                            hide-details
                          >
                            <template v-slot:thumb-label>
                              {{ tips }} %
                            </template>
                          </v-slider>
                          <small class="Description mt-1">{{ $t('your tip will go directly to the seller') }}</small>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="enabled.charity">
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div class="SectionTitle mb-2">{{ $t('charity') }}</div>
                        <div align="center">
                          <v-slider
                            dark
                            v-model="charity"
                            ticks="always"
                            :max="20"
                            :min="0"
                            thumb-label="always"
                            style="width: 80%; min-width: 250px;"
                            hide-details
                          >
                            <template v-slot:thumb-label>
                              {{ charity }} %
                            </template>
                          </v-slider>
                          <small class="Description mt-1">{{ $t('your donation goes directly to the organization sponsored by the seller') }}</small>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn class="mt-5 mx-2 rounded-lg" dark @click="step--">{{ $t('back') }}</v-btn>
                <v-btn class="mt-5 rounded-lg" x-large @click="step++">{{ $t('continue') }}</v-btn>
              </div>
              <div v-if="step === 2" class="fade-in-fwd">
                <div class="SectionTitle">Shipping Information</div>
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div align="center" class="my-2">
                          <v-text-field dark outlined label="Name" dense style="width: 90%;"></v-text-field>
                          <div style="width: 90%;" class="d-inline-flex" v-if="$vuetify.breakpoint.width >= 600">
                            <v-text-field class="mr-2" dark outlined label="Email" type="email" dense style="width: 60%;"></v-text-field>
                            <v-text-field class="ml-2" dark outlined label="Phone" type="phone" dense style="width: 40%;"></v-text-field>
                          </div>
                          <div style="width: 90%;" v-if="$vuetify.breakpoint.width < 600">
                            <v-text-field class="" dark outlined label="Email" type="email" dense></v-text-field>
                            <v-text-field class="" dark outlined label="Phone" type="phone" dense></v-text-field>
                          </div>
                          <div style="width: 90%;" class="d-inline-flex" v-if="$vuetify.breakpoint.width >= 600">
                            <v-text-field class="mr-2" dark outlined label="Address" dense style="width: 70%;"></v-text-field>
                            <v-text-field class="ml-2" dark outlined label="City" dense style="width: 30%;"></v-text-field>
                          </div>
                          <div style="width: 90%;" v-if="$vuetify.breakpoint.width < 600">
                            <v-text-field class="" dark outlined label="Address" dense></v-text-field>
                            <v-text-field class="" dark outlined label="City" dense></v-text-field>
                          </div>
                          <div style="width: 90%;" class="d-inline-flex">
                            <v-text-field class="mr-2" dark outlined label="Country" dense style="width: 50%;"></v-text-field>
                            <v-text-field class="ml-2" dark outlined label="ZIP" dense style="width: 50%;"></v-text-field>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn class="mt-5 mx-2 rounded-lg" dark @click="step--">Back</v-btn>
                <v-btn class="mt-5 mx-2 rounded-lg" x-large @click="step++">Continue</v-btn>
              </div>
              <div v-if="step === 3" class="fade-in-fwd">
                <div class="SectionTitle">{{ $t('payment methods') }}</div>
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">

                        <div align="center">
                          <v-container fluid class="px-0">
                            <v-row dense>
                              <v-col v-for="(item, index) in paymentMethodsSupported.filter(item => item.pos === pos || item.all === true)" v-bind:key="index">
                                <v-btn :disabled="item.disabled" @click="payWith(item.key)" class="mx-2" height="72"><v-img :src="item.logo" height="48px" width="100px" contain></v-img></v-btn>
                                <div class="overline white--text" style="font-size: 60% !important; line-height: 200%">{{ item.name }}</div>
                              </v-col>
                              <v-col v-for="(item, index) in Array($vuetify.breakpoint.width < 960 ? $vuetify.breakpoint.width < 600 ? 2 : 4 : 12)" v-bind:key="'fill' + index">
                                <div style="width: 112px" class="mx-2"></div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn class="mt-5 mx-2 rounded-lg" dark @click="step--">{{ $t('back') }}</v-btn>
              </div>
              <div v-if="step === 4" class="fade-in-fwd">
                <div class="SectionTitle">{{ $t('payment with') }} {{ paymentType }}</div>
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div align="center">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <v-progress-linear indeterminate height="10" color="white" v-if="!qr"></v-progress-linear>
                                <v-img :src="qr" max-height="250px" max-width="250px" contain class="rounded-xl" v-show="qr" />
                                <div v-if="paymentMessage" class="SectionTitle mt-2" style="font-size: 80%">
                                  {{ paymentMessage }}
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn class="mt-2 mx-2 rounded-lg" dark @click="step--">{{ $t('back') }}</v-btn>
              </div>
              <div v-if="step === 5" class="fade-in-fwd">
                <v-container fluid>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <div class="Section" align="left">
                        <div align="center">
                          <v-container fluid>
                            <v-row>
                              <v-col>
                                <div v-if="!complete" class="my-16">
                                  <v-progress-linear indeterminate height="10" color="white" v-if="!complete" class="mt-16">
                                  </v-progress-linear>
                                  <span class="white--text mb-16">{{ $t('processing') }}</span>
                                </div>
                                <div v-if="complete" class="fade-in-fwd">
                                  <div v-if="warning">
                                    <div class="text-h3 white--text">{{ $t('payment problem') }}</div>
                                    <div :class="{ 'my-10': $vuetify.breakpoint.height > 900 }">
                                      <WarningIcon />
                                    </div>
                                    <div class="Description mb-2 text-h6">{{ $t('there is a problem processing payments, please try again later') }}</div>
                                  </div>
                                  <div v-if="fail">
                                    <div class="text-h3 white--text">{{ $t('payment failed') }}</div>
                                    <div :class="{ 'my-10': $vuetify.breakpoint.height > 900 }">
                                      <FailIcon />
                                    </div>
                                    <div class="Description mb-2 text-h6">{{ $t('your transaction was rejected by the financial institution') }}</div>
                                  </div>
                                  <div v-if="success">
                                    <div class="text-h3 white--text">{{ $t('payment success') }}</div>
                                    <div :class="{ 'my-10': $vuetify.breakpoint.height > 900 }">
                                      <SuccessIcon />
                                    </div>
                                    <div>
                                      <div class="Description mb-2 text-h6">{{  $t('print your voucher') }}</div>
                                      <v-btn class="rounded-lg" @click="printVoucher">{{ $t('print voucher') }}</v-btn>
                                    </div>
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-btn v-if="complete" class="mx-2 rounded-lg" x-large @click="reset">{{ $t('new payment') }}</v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn v-if="complete && $vuetify.breakpoint.height >= 900" class="mt-5 mx-2 rounded-lg" x-large @click="step--">
                  {{ $t('go back to site') }}</v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="false">
            <v-col align-self="center" align="center">
              <div class="SeparatorDark mb-5"></div>
              <v-img max-height="30px" max-width="100px" contain class="d-inline-flex mx-2" :src="require('@/assets/p4g.svg')"  />
              <v-img max-height="25px" max-width="100px" contain class="d-inline-flex mx-2" :src="require('@/assets/empowered.svg')"  />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div id="voucher" style="width: 192px; position: absolute; top: 0; z-index: -100" v-show="voucher">
        <div class="pb-5" align="center">
          <hr />
          <div class="VoucherTitle">{{ $t('voucher') }}</div>
          <div class="VoucherSmall">{{ $t('client') }}</div>
          <div class="VoucherMerchant my-2">{{ merchant }}</div>
          <v-container class="VoucherDetail">
            <v-row dense>
              <v-col class="pa-0" align="right">
                <div class="mr-1">{{ $t('amount') }}:</div>
              </v-col>
              <v-col class="pa-0" align="left">
                <div class="">{{ currency }}$ {{ fixedNumber(amount) }}</div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pa-0" align="right">
                <div class="mr-1">{{ $t('service fee') }}:</div>
              </v-col>
              <v-col class="pa-0" align="left">
                <div class="">{{ currency }}$ {{ fixedNumber(numericFee) }}</div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pa-0" align="right">
                <div class="mr-1">{{ $t('network fee') }}:</div>
              </v-col>
              <v-col class="pa-0" align="left">
                <div class="">{{ currency }}$ {{ fixedNumber(network) }}</div>
              </v-col>
            </v-row>
            <v-row dense class="mt-5">
              <v-col class="pa-0" align="right">
                <div class="font-weight-bold mr-1">Subtotal:</div>
              </v-col>
              <v-col class="pa-0" align="left">
                <div class="font-weight-bold">{{ currency }}$ {{ fixedNumber(subtotal) }}</div>
              </v-col>
            </v-row>
            <v-row dense v-if="tax > 0">
              <v-col class="pa-0" align="right">
                <div class="mr-1">{{ taxType.toUpperCase() }}:</div>
                <small v-if="state"> {{ getStateName(this.state) }}</small>
              </v-col>
              <v-col class="pa-0" align="left">
                <div class="">{{ currency }}$ {{ fixedNumber(numericTax) }}</div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pa-0" align="right">
                <div class="mr-1">{{ $t('tips') }}:</div>
              </v-col>
              <v-col class="pa-0" align="left">
                <div class="">{{ currency }}$ {{ fixedNumber(numericTips) }}</div>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="pa-0" align="right">
                <div class="mr-1">{{ $t('charity') }}:</div>
              </v-col>
              <v-col class="pa-0" align="left">
                <div class="">{{ currency }}$ {{ fixedNumber(numericCharity) }}</div>
              </v-col>
            </v-row>
            <v-row dense style="font-size: 110%">
              <v-col class="pa-0" align="right">
                <div class="font-weight-bold mr-1">Total:</div>
              </v-col>
              <v-col class="pa-0" align="left">
                <div class="font-weight-bold">{{ currency }}$ {{ fixedNumber(total) }}</div>
              </v-col>
            </v-row>
          </v-container>
          <div class="VoucherDetail">
            {{ new Date().toLocaleString() }}
          </div>
          <v-img :src="require('@/assets/qrDemo.png')" max-width="80%" contain></v-img>
          <div class="mb-2 VoucherCode">
            <div>{{ $t('transaction code') }}</div>
            <div>eac01aac0b2187a5149ad2c55031e362</div>
          </div>
          <div class="my-1 VoucherCode" v-if="paymentType === 'Credit Card' && debug.card">
            <div>Credit Card</div>
            <div>**** **** **** {{ debug.card.slice(-4) }}</div>
          </div>
          <div class="VoucherDetail">
            {{ $t('thanks for using') }}
            <div class="font-weight-bold">Pay4Good</div>
          </div>
          <hr />
        </div>
      </div>
      <v-dialog fullscreen v-model="globalLoading">
        <v-container fluid class="fill-height" style="background-color: #1D4770ee;">
          <v-row>
            <v-col align-self="center" align="center">
              <v-progress-circular color="white" width="20" indeterminate size="200"></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
      </v-dialog>
      <div v-if="terms" class="Terms pa-5" align="center">
        <div class="SectionTitle black--text">Terms</div>
        <div align="left" class="pa-5">
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras ultricies porta suscipit. Pellentesque in tellus ac metus imperdiet egestas ut vel odio. Morbi congue leo lorem, eget vehicula enim auctor ut. Suspendisse id justo cursus risus egestas tincidunt. In gravida commodo dolor, at ornare orci lacinia a. Ut maximus sem vitae ante egestas hendrerit. Nam accumsan metus eget tincidunt tempor. Sed bibendum aliquet ante vel aliquam. Aliquam volutpat ante et lorem consectetur, placerat bibendum metus dignissim. Aenean dignissim dui ornare, porttitor ipsum eget, volutpat felis. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam eu ligula rutrum erat ornare elementum eu quis elit. Etiam leo lectus, fringilla ac leo ut, gravida porta odio. Maecenas quis dapibus arcu, in molestie neque. Sed pellentesque, dolor quis mattis accumsan, velit enim bibendum turpis, at mattis urna elit tempus justo. Phasellus quis dolor nisl.
          </p><p>
          Fusce vel dolor ut enim feugiat luctus. Curabitur lacus ex, pulvinar at vulputate fermentum, fermentum rhoncus arcu. Praesent consectetur tortor metus, eget porta lacus elementum sit amet. Quisque nec finibus lectus. Nunc rutrum felis ac laoreet faucibus. Nulla quis volutpat sem. Pellentesque ornare mi ac sem tempor tristique et vel orci. Curabitur tempor placerat urna, vel fringilla mi. Vestibulum in leo id justo congue mattis. Fusce faucibus blandit est eget blandit. Nam vitae nibh euismod, aliquam erat et, vehicula sapien. Suspendisse potenti. Donec ac erat auctor, viverra turpis eleifend, cursus dui. Curabitur sit amet pulvinar enim, et pulvinar ante.
        </p><p>
          Integer faucibus dapibus eros at efficitur. Ut tempus scelerisque sem ac fringilla. Cras eget aliquam quam. Donec vel metus libero. Suspendisse ut posuere metus. Vivamus hendrerit leo enim, at elementum tellus dignissim eget. Vestibulum ut maximus lacus. Nunc efficitur, quam eu accumsan varius, leo velit fringilla nisl, vel pellentesque purus dolor auctor dui. Vestibulum nec libero convallis, mollis arcu molestie, hendrerit odio. Donec eget sagittis dui. Aenean at mattis nibh, non egestas urna. Pellentesque eu convallis nulla.
        </p><p>
          Ut fermentum eu orci id tincidunt. Quisque tincidunt nisi ut lectus lacinia lacinia. Sed ornare magna sed velit ullamcorper blandit. Integer felis sapien, pellentesque quis turpis eget, faucibus blandit felis. Maecenas quis magna volutpat, vulputate velit non, vehicula ex. Vivamus in accumsan urna. Fusce non nisl sagittis, pellentesque tellus vel, ornare quam. Donec a velit cursus, pharetra mi in, egestas nulla. Etiam maximus, ex ut sollicitudin cursus, augue metus luctus felis, id mattis nibh libero sagittis purus. Mauris suscipit lacus in lectus finibus ultricies at quis sem. Fusce at faucibus enim. Nullam eget cursus mauris, eleifend dictum quam. Donec venenatis at mauris sed egestas.
        </p><p>
          Phasellus efficitur nibh rutrum libero rhoncus, sed lobortis justo feugiat. Fusce tincidunt vitae turpis at tempor. Nam sed lectus diam. Morbi vel aliquet arcu, nec bibendum nibh. Nulla hendrerit vel quam blandit cursus. Aenean tincidunt neque fermentum neque tempus facilisis. Proin pharetra condimentum blandit. Aenean ultrices felis ante.
        </p>
        </div>
        <div>
          <div>Debug</div>
          <div>
            {{ $vuetify.breakpoint.width }} x {{ $vuetify.breakpoint.height }}
          </div>
        </div>
        <v-btn color="#1D4770" dark x-large class="rounded-lg" @click="terms = !terms">Close</v-btn>
      </div>
    </div>
</template>

<script>
import './_POSView.scss'
import { createInstantPayment, generateQR, getChallenge, getIdentity, waitChallenge, waitPayment } from 'id4good.auth'
import { Wallet } from 'ethers'
import Printer from '@/plugins/printer'
import Card from '@/plugins/card'
import SuccessIcon from '@/components/Icons/SuccessIcon/SuccessIcon'
import FailIcon from '@/components/Icons/FailIcon/FailIcon'
import WarningIcon from '@/components/Icons/WarningIcon/WarningIcon'
import html2canvas from 'html2canvas'
import billing from '@/mixins/billing'
import common from '@/mixins/common'
import WalletConnect from '@walletconnect/client'
import { Capacitor } from '@capacitor/core'
import NFC from '@/plugins/nfc'
import { Country, State } from 'country-state-city'

export default {
  name: 'POSView',
  components: { WarningIcon, FailIcon, SuccessIcon },
  data () {
    return {
      voucher: false,
      terms: false,
      globalLoading: false,
      merchant: 'Pay4Gobal Demo',
      finish: false,
      step: 0,
      enabled: {
        shipping: false,
        fastPayment: false,
        tips: true,
        charity: true
      },
      taxType: 'tax',
      currency: 'USD',
      panelHeight: 95,
      address: null,
      loadingIdentity: false,
      qr: null,
      paymentType: '',
      paymentMessage: null,
      complete: false,
      success: false,
      fail: false,
      warning: false,
      link: '',
      country: null,
      state: null,
      states: null,
      countries: [],
      taxList: [],
      convert: null,
      debug: '',
      pos: true,
      pay4good: {
        payment: null
      },
      paymentMethodsSupported: [
        {
          key: 'cc',
          name: 'Credit Card',
          logo: require('@/assets/visamaster.png'),
          pos: true,
          all: false
        },
        {
          key: 'smart',
          name: 'Smart Pay',
          logo: require('@/assets/gpayapay.png'),
          pos: true,
          all: false
        },
        {
          key: 'p4g',
          name: 'Pay4Good',
          logo: require('@/assets/_p4g.svg'),
          pos: false,
          all: false
        },
        {
          key: 'i4g',
          name: 'ID4Good',
          logo: require('@/assets/i4g.png'),
          pos: true,
          all: true
        },
        {
          key: 'i4gv2',
          name: 'iD4Good',
          logo: require('@/assets/id4gv2.png'),
          pos: true,
          all: true
        },
        {
          key: 'gpay',
          name: 'Google Pay',
          logo: require('@/assets/gpay.png'),
          pos: false,
          all: false
        },
        {
          key: 'apay',
          name: 'Apple Pay',
          logo: require('@/assets/apay.png'),
          pos: false,
          all: false
        },
        {
          key: 'nfcpay',
          name: 'NFCPay',
          logo: require('@/assets/nfcpay.png'),
          pos: true,
          all: true
        },
        {
          key: 'alienza',
          name: 'Alianza Lima',
          logo: require('@/assets/alienza.png'),
          pos: true,
          all: false
        },
        {
          key: 'backus',
          name: 'Backus',
          logo: require('@/assets/backus.png'),
          pos: true,
          all: false
        },
        {
          key: 'yape',
          name: 'Yape',
          logo: require('@/assets/yape.png'),
          pos: true,
          all: false,
          disabled: true
        },
        {
          key: 'plin',
          name: 'Plin',
          logo: require('@/assets/plin.png'),
          pos: true,
          all: false,
          disabled: true
        },
        {
          key: 'alipay',
          name: 'AliPay',
          logo: require('@/assets/alipay.png'),
          pos: false,
          all: false
        },
        {
          key: 'btc',
          name: 'Bitcoin',
          logo: require('@/assets/btc.svg'),
          pos: true,
          all: true
        },
        {
          key: 'chivo',
          name: 'Chivo Wallet',
          logo: require('@/assets/chivowallet.png'),
          pos: true,
          all: true
        },
        {
          key: 'beach',
          name: 'Beach Wallet',
          logo: require('@/assets/beachwallet.png'),
          pos: true,
          all: true
        },
        {
          key: 'ln',
          name: 'Lightning',
          logo: require('@/assets/lightning.svg'),
          pos: true,
          all: true
        },
        {
          key: 'wc',
          name: 'Ethereum',
          logo: require('@/assets/etherium.svg'),
          pos: true,
          all: true
        },
        {
          key: 'sambax',
          name: 'Sambax',
          logo: require('@/assets/sambax.svg'),
          pos: true,
          all: false,
          disabled: true
        },
        {
          key: 'binance',
          name: 'Binance',
          logo: require('@/assets/binance.svg'),
          pos: true,
          all: false,
          disabled: true
        },
        {
          key: 'coinbase',
          name: 'Coinbase',
          logo: require('@/assets/coinbase.svg'),
          pos: true,
          all: false,
          disabled: true
        },
        {
          key: 'ledger',
          name: 'Ledger',
          logo: require('@/assets/ledger.png'),
          pos: true,
          all: false,
          disabled: true
        },
        {
          key: 'paypal',
          name: 'Paypal',
          logo: require('@/assets/paypal.png'),
          pos: true,
          all: false,
          disabled: true
        }
      ]
    }
  },
  mixins: [billing, common],
  mounted () {
    this.$axios.get('https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json').then((result) => {
      this.convert = result.data
      this.convert.usd.apta = 1 / 8.02
    })
    this.countries = Country.getAllCountries()
    this.taxList = require('@/assets/tax.json')
    this.country = 'CA'
    // setTimeout(() => { this.state = 'CA' }, 100)
    if (this.$vuetify.breakpoint.width < 720) {
      setTimeout(() => {
        this.panelHeight = 32
        this.completePanel()
      }, 2000)
    }
    // this.getID()
    console.log('readyx')
  },
  watch: {
    step: {
      deep: true,
      handler: async function (value, old) {
        document.body.scrollTo(0, 0)
        if (value === 2 && value > old) {
          if (!this.enabled.shipping) {
            this.step++
          }
        }
        if (value === 2 && value < old) {
          if (!this.enabled.shipping) {
            this.step--
          }
        }
        if (value === 3 && old < value) {
          console.log('generate payment')
          this.globalLoading = true
          this.pay4good.payment = await createInstantPayment('0xB621dB1Cfe6BdE1987ab18e92bD6F738cD43857b', 'Demo Merchant', this.total, '')
          this.globalLoading = false
        }
      }
    },
    country: {
      deep: true,
      handler: function (value) {
        this.tax = 0
        this.taxType = 'tax'
        this.states = null
        this.state = null
        if (this.taxList[value]) {
          this.tax = this.taxList[value].rate * 100
          this.taxType = this.taxList[value].type
          if (this.taxList[value].states) {
            this.states = State.getStatesOfCountry(this.country)
          }
        }
      }
    },
    state: {
      deep: true,
      handler: function (value) {
        if (!value) {
          return
        }
        this.tax = 0
        this.taxType = 'tax'
        if (this.taxList[this.country].states) {
          if (this.taxList[this.country].states[value]) {
            this.tax = this.taxList[this.country].states[value].rate * 100
            this.taxType = this.taxList[this.country].states[value].type
          }
        }
      }
    }
  },
  methods: {
    reset () {
      this.step = 0
      this.complete = false
      this.fail = false
      this.warning = false
      this.success = false
      this.merchant = 'Merchant Demo'
      this.finish = false
      this.amount = 0
      this.tips = 10
      this.charity = 5
      this.qr = null
      this.paymentType = ''
      this.paymentMessage = null
      this.link = ''
      this.country = null
      this.state = null
      this.debug = ''
      this.pay4good = {
        payment: null
      }
      this.$axios.get('https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json').then((result) => {
        this.convert = result.data
        this.convert.usd.apta = 1 / 8.02
      })
      this.countries = Country.getAllCountries()
      this.taxList = require('@/assets/tax.json')
      this.country = 'CA'
      // setTimeout(() => { this.state = 'CA' }, 100)
      if (this.$vuetify.breakpoint.width < 720) {
        setTimeout(() => {
          this.panelHeight = 32
          this.completePanel()
        }, 1000)
      }
    },
    async pay () {
      this.step++
    },
    addNumber (value) {
      if (this.amount.toString().includes('.')) {
        if (this.amount.toString().split('.')[1].length >= 2) {
          return
        }
        this.amount = this.amount.toString() + value
        return
      }
      this.amount = parseFloat(this.amount.toString() + value)
      if (this.amount > 100000) {
        this.amount = 100000
      }
    },
    addPoint () {
      if (this.amount.toString().indexOf('.') < 0) {
        this.amount = this.amount + '.'
      }
    },
    removeNumber () {
      this.amount = parseFloat(this.amount.toString().slice(0, -1))
      console.log(this.amount)
      if (isNaN(this.amount)) {
        this.amount = 0
      }
    },
    async getID () {
      if (this.address) {
        return
      }
      const challenge = await getChallenge('wallet4good.com')
      if (this.$device.mobile) {
        if (this.$device.ios) {
          this.link = 'https://id4good.com/' + challenge.code
        }
        if (this.$device.android) {
          this.link = 'id4good://wallet4good/' + challenge.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
        }
      } else {
        this.qr = await generateQR(challenge.code)
      }
      // console.log('load')
      const expire = setTimeout(() => {
        this.getID()
      }, 5 * 60000)
      waitChallenge(challenge.hash, 0).then(async (result) => {
        this.loadingIdentity = true
        clearTimeout(expire)
        const identity = await getIdentity(challenge.hash)
        if (identity.data.data !== undefined) {
          try {
            const data = JSON.parse(identity.data.data)
            const wallet = new Wallet(data.privateKey)
            this.address = wallet.getAddress()
          } catch (e) {
            const data = identity.data.data
            const wallet = new Wallet(data.privateKey)
            this.address = wallet.getAddress()
          }
        }
      })
    },
    startPanel () {
      console.log('start')
      document.body.style.overflow = 'hidden'
    },
    completePanel () {
      document.body.style.overflow = 'scroll'
      console.log('complete')
      if (this.panelHeight < 50) {
        this.panelHeight = 32
      }
      if (this.panelHeight >= 50) {
        this.panelHeight = 95
      }
      this.$refs.default.style.height = this.panelHeight + '%'
    },
    movePanel (event) {
      let percent = parseFloat(event.targetTouches[0].clientY / window.innerHeight).toFixed(2) * 100
      if (percent > 95) {
        percent = 95
      }
      if (percent < 30) {
        percent = 32
      }
      this.panelHeight = percent
      this.$refs.default.style.height = percent + '%'
    },
    changePanel () {
      if (this.panelHeight >= 50) {
        this.panelHeight = 32
      } else {
        this.panelHeight = 95
      }
      this.completePanel()
    },
    getEquivalentCurrency (code) {
      if (!this.convert) {
        return null
      }
      const find = this.convert.usd[code.toLowerCase()]
      if (find) {
        return find
      }
      return null
    },
    getCountryCurrency () {
      const find = this.countries.find(item => item.isoCode === this.country)
      if (find) {
        return find.currency
      }
      return 'USD'
    },
    getCountryName () {
      const find = this.countries.find(item => item.isoCode === this.country)
      if (find) {
        return find.name
      }
      return ''
    },
    getStateName () {
      const find = this.states.find(item => item.isoCode === this.state)
      if (find) {
        return find.name
      }
      return ''
    },
    getCountryFlag () {
      const find = this.countries.find(item => item.isoCode === this.country)
      if (find) {
        return find.flag
      }
      return ''
    },
    async printVoucher () {
      this.voucher = true
      this.globalLoading = true
      setTimeout(() => {
        html2canvas(document.querySelector('#voucher')).then(canvas => {
          const image = canvas.toDataURL().split(',').pop()
          Printer.voucher({ image: image })
          this.globalLoading = false
          this.voucher = false
        })
      }, 500)
    },
    async waitPay (hash) {
      waitPayment(hash).then(result => {
        this.step++
        console.log('wait', this.step)
        if (result === 'complete') {
          this.success = true
          this.complete = true
          return
        }
        waitPayment(hash, 0, true).then(result => {
          console.log('finish')
          this.success = true
          this.complete = true
        }).catch(error => {
          console.log(error, this.step)
          if (this.step > 3) {
            this.fail = true
            this.complete = true
          }
          console.log(error)
        })
      }).catch(error => {
        console.log(error, this.step)
        if (this.step > 3) {
          this.step++
          this.fail = true
          this.complete = true
        }
        console.log(error)
      })
    },
    async payWith (method) {
      try {
        this.qr = null
        this.step++
        if (method === 'p4g') {
          const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
          this.paymentType = paymentMethod.name
          this.paymentMessage = 'Scan with your Phone'
          this.qr = await this.generateCodeQR('https://pay4good.com/?hash=' + this.pay4good.payment.hash, paymentMethod.logo, 300, 300, '#10395D', '#0076b4', '#FFFFFF')
          await this.waitPay(this.pay4good.payment.hash)
          return
        }
        if (method === 'i4gv2') {
          const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
          this.paymentType = paymentMethod.name
          this.paymentMessage = 'Scan with iD4Good (New)'
          const payment = (await this.$axios.post('https://4good.network/api/payments', {
            iD: 'iD4G<ad9bfc225b1d4ab994e730e537389c49cdb5147122d8595c033cc4ab7b8c12b07fbbbe41c4629aa71b7dba19afd6df55', title: 'Demo Payment', description: 'Demo Payment Description', amount: this.amount, net: true, tips: this.tips / 100, tax: { rate: this.tax / 100 }, charity: this.charity / 100
          })).data
          const service = (await this.$axios.post('https://4good.network/api/payments/services/id4good/' + payment.hash + '/t4g-usd')).data
          const signature = (await this.$axios.post('https://4good.network/api/identification/signatures', {
            requests: service,
            trigger: payment.hash
          })).data
          this.qr = signature.qr
          await this.waitPay(this.pay4good.payment.hash)
        }
        if (method === 'i4g' || method === 'alienza' || method === 'backus') {
          const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
          this.paymentType = paymentMethod.name
          this.paymentMessage = 'Scan with iD4Good'
          this.qr = await this.generateCodeQR(this.pay4good.payment.code, paymentMethod.logo, 300, 300, '#10395D', '#0076b4', '#FFFFFF')
          await this.waitPay(this.pay4good.payment.hash)
        }
        if (method === 'ln' || method === 'chivo' || method === 'beach') {
          const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
          this.paymentType = paymentMethod.name
          this.paymentMessage = 'Use your Lightning Wallet'
          const { charge } = (await this.$axios.get('https://pay4good.com/api/createBitcoinPayment', {
            params: {
              hash: this.pay4good.payment.hash,
              crc: Date.now()
            }
          })).data
          this.qr = await this.generateCodeQR('lightning:' + charge.lightning_invoice.payreq, paymentMethod.logo, 300, 300, '#10395D', '#7B1AF7', '#FFFFFF')
          await this.waitPay(this.pay4good.payment.hash)
        }
        if (method === 'btc') {
          const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
          this.paymentType = paymentMethod.name
          this.paymentMessage = 'Use your Bitcoin Wallet'
          const { charge } = (await this.$axios.get('https://pay4good.com/api/createBitcoinPayment', {
            params: {
              hash: this.pay4good.payment.hash,
              crc: Date.now()
            }
          })).data
          this.qr = await this.generateCodeQR(charge.uri, paymentMethod.logo, 300, 300, '#10395D', '#f7931a', '#FFFFFF')
          await this.waitPay(this.pay4good.payment.hash)
        }
        if (method === 'wc') {
          const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
          this.paymentType = paymentMethod.name
          this.paymentMessage = 'Use your Wallet with Wallet Connect'
          localStorage.removeItem('walletconnect')
          const connector = new WalletConnect({
            bridge: 'https://bridge.walletconnect.org',
            clientMeta: {
              name: 'Pay4Good'
            }
          })
          // await connector.updateSession()
          const uri = connector.uri
          console.log(uri)
          this.qr = await this.generateCodeQR(uri, paymentMethod.logo, 300, 300, '#10395D', '#627EEA', '#FFFFFF')
          await this.waitPay(this.pay4good.payment.hash)
        }
        if (method === 'cc') {
          const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
          this.paymentType = paymentMethod.name
          this.paymentMessage = 'Use your Card'
          const result = await Card.pay({ amount: this.amount })
          console.log(result)
          this.debug = result
          if (result !== 'fail') {
            this.success = true
          } else {
            this.fail = true
          }
          this.step++
          setTimeout(() => {
            this.complete = true
          }, 1000)
        }
        if (method === 'smart') {
          const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
          this.paymentType = paymentMethod.name
          this.paymentMessage = 'Use your Smart Device'
          try {
            const result = await Card.pay({ amount: this.amount })
            console.log(result)
            this.debug = result
            this.success = true
          } catch (e) {
            this.fail = true
          }
          this.step++
          setTimeout(() => {
            this.complete = true
          }, 1000)
        }
        if (method === 'nfcpay') {
          const paymentMethod = this.paymentMethodsSupported.find(item => item.key === method)
          this.paymentType = paymentMethod.name
          this.paymentMessage = 'Use your NFC Pay'
          this.qr = require('@/assets/scan.png')
          if (Capacitor.isNativePlatform()) {
            const result = await NFC.read({ amount: this.amount })
            console.log(result)
            this.debug = result
            if (result !== 'fail') {
              this.success = true
            } else {
              this.fail = true
            }
            this.step++
            setTimeout(() => {
              this.complete = true
            }, 1000)
          } else {
            try {
              await navigator.permissions.query({ name: 'nfc' })
              if ('NDEFReader' in window) {
                console.log('reader NFC')
                const ndef = new window.NDEFReader()
                ndef.scan().then(() => {
                  console.log('Scan started successfully.')
                  ndef.onreadingerror = () => {
                    if (!this.complete) {
                      this.fail = true
                      this.step++
                      this.complete = true
                    }
                    console.log('Cannot read data from the NFC tag. Try another one?')
                  }
                  ndef.onreading = event => {
                    if (!this.complete) {
                      const message = event.message
                      console.log(message)
                      console.log('NDEF message read.')
                      this.success = true
                      this.step++
                      this.complete = true
                      console.log('NDEF message read. ok')
                    }
                  }
                }).catch(error => {
                  if (!this.complete) {
                    console.log(`Error! Scan failed to start: ${error}.`)
                    this.fail = true
                    this.step++
                    this.complete = true
                  }
                })
              } else {
                console.log('error')
              }
            } catch (e) {
              console.log('error')
              console.log(e)
              this.fail = true
            }
          }
        }
      } catch (e) {
        this.debug = e
      }
    }
  }
}
</script>
