<template>
    <div class="LoadingView">
      <div :class="{ Logo: true, 'slide-in-top': true, 'slide-out-top': finish }">
        <v-container fluid class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <v-img max-width="500px" width="80%" :src="require('@/assets/p4g.svg')" style="filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));" />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="Loading overline">
        <v-progress-circular color="#1D4770" indeterminate width="20" size="200"></v-progress-circular>
      </div>
      <div :class="{ Footer: true, 'slide-in-bottom': true, 'slide-out-bottom': finish }">
        <v-container fluid class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <v-img max-width="150px" :src="require('@/assets/empowered.svg')"  />
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
</template>

<script>
import './_LoadingView.scss'
export default {
  name: 'LoadingView',
  data () {
    return {
      finish: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.finish = true
    }, 1000)
    setTimeout(() => {
      this.$router.push({ name: 'home' })
    }, 1500)
  }
}
</script>
