<template>
  <v-app>
    <div id="version">{{ version }}</div>
    <v-main>
      <router-view/>
    </v-main>
    <v-btn v-if="false" fixed bottom left small icon @click="$vuetify.theme.dark = !$vuetify.theme.dark"><v-icon>mdi-brightness-6</v-icon></v-btn>
  </v-app>
</template>

<script>
import '@/animations/animations.scss'

export default {
  name: 'App',
  data () {
    return {
      version: 'alpha'
    }
  },
  mounted () {
    this.version = 'v' + process.env.VUE_APP_VERSION + '-alpha'
  }
}
</script>

<style>
#version {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 0.6rem;
  text-transform: uppercase;
}
#app.theme--dark {
  background: rgb(45, 71, 151);
  background: linear-gradient(136deg, rgb(45, 71, 151) 0%, rgb(4, 19, 63) 100%);
}

#app.theme--light {
  background: rgb(234,242,246);
  /*background: linear-gradient(136deg, rgb(241, 243, 245) 0%, rgb(202, 212, 218) 100%);*/
}
</style>
