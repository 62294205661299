<template>
    <div class="PublicView">
      <QuickMenuComponent />
      <v-container>
        <v-row>
          <v-col align-self="center" align="center">
            <QuickMenuBusinessComponent />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="SectionTitle">
              Public Information
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="Section">
              <v-text-field prepend-icon="mdi-google-my-business" label="Public Business Name" filled outlined></v-text-field>
              <div class="d-flex">
                <v-text-field class="mx-2" prepend-icon="mdi-email" label="Support Email" type="email" filled outlined></v-text-field>
                <v-text-field class="mx-2" prepend-icon="mdi-phone" label="Public Contact Number" filled outlined></v-text-field>
              </div>
              <v-container fluid class="pa-0">
                <v-row>
                  <v-col cols="8" align-self="center" align="center">
                    <v-text-field prepend-icon="mdi-web" label="Business Website" filled outlined></v-text-field>
                    <v-text-field prepend-icon="mdi-hammer-screwdriver" label="Support URL" filled outlined></v-text-field>
                    <v-text-field prepend-icon="mdi-stamper" label="Policy URL" filled outlined></v-text-field>
                    <v-text-field prepend-icon="mdi-file-sign" label="Terms URL" filled outlined></v-text-field>
                  </v-col>
                  <v-col align="center">
                    <div align="left" class="ml-10">
                      <span class="SectionSubtitle">Logo</span>
                      <v-img :src="require('@/assets/logo_demo.png')" max-width="300px" max-height="300px" height="300px" width="300px" contain class="Logo">
                        <div align="right" style="position: absolute; width: 100%; bottom: 3px">
                          <v-btn icon x-small class="mx-1"><v-icon>mdi-upload</v-icon></v-btn>
                          <v-btn icon x-small class="mx-1 mr-2"><v-icon>mdi-image-edit-outline</v-icon></v-btn>
                        </div>
                      </v-img>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
              <div align="left" class="ml-10" v-if="false">
                <span class="SectionSubtitle">Colors</span>
                <div class="d-flex ColorsBox">
                  <div class="colorSelect" :style="{ backgroundColor: primaryColor }" @click="primaryColorPicker = true">
                    <div :class="{ colorPicker: true }" v-if="primaryColorPicker" align="center">
                      <v-color-picker
                        dot-size="25"
                        swatches-max-height="200"
                        mode="rgba"
                        v-model="primaryColor"
                        style="background-color: transparent"
                      ></v-color-picker>
                      <v-btn @click.stop="primaryColorPicker = false">Close</v-btn>
                    </div>
                  </div>
                  <div class="colorSelectText">Primary Color</div>
                  <div class="colorSelect" :style="{ backgroundColor: secondaryColor }" @click="secondaryColorPicker = true">
                    <div :class="{ colorPicker: true }" v-if="secondaryColorPicker" align="center">
                      <v-color-picker
                        dot-size="25"
                        swatches-max-height="200"
                        mode="rgba"
                        v-model="secondaryColor"
                        style="background-color: transparent"
                      ></v-color-picker>
                      <v-btn @click.stop="secondaryColorPicker = false">Close</v-btn>
                    </div>
                  </div>
                  <div class="colorSelectText">Secondary Color</div>
                </div>
              </div>
              <div align="right" class="my-5">
                <v-btn class="px-10" x-large color="success">Save</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_PublicView.scss'
import QuickMenuComponent from '@/components/QuickMenuComponent/QuickMenuComponent'
import QuickMenuBusinessComponent from '@/components/QuickMenuBusinessComponent/QuickMenuBusinessComponent'
export default {
  name: 'PublicView',
  components: { QuickMenuBusinessComponent, QuickMenuComponent },
  data () {
    return {
      primaryColor: '#454545',
      primaryColorPicker: false,
      secondaryColor: '#454545',
      secondaryColorPicker: false
    }
  }
}
</script>
