import { render, staticRenderFns } from "./POSView.vue?vue&type=template&id=4eefacbc&"
import script from "./POSView.vue?vue&type=script&lang=js&"
export * from "./POSView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VContainer,VDialog,VIcon,VImg,VProgressCircular,VProgressLinear,VRow,VSlider,VTextField})
