import QRCodeStyling from 'qr-code-styling'

export default {
  data () {
    return {

    }
  },
  methods: {
    async generateID4GoodQR (code) {
      const qrcode = new QRCodeStyling({
        width: 300,
        height: 300,
        margin: 10,
        data: code,
        image: require('@/assets/methods/id4good.svg'),
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#0076b4'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 10
        }
      })
      await qrcode._drawingPromise
      const image = await qrcode._canvas.getCanvas().toDataURL('image/png')
      return image
    },
    async generateCodeQR (code, logo, width, height, dotColor, cornerColor, backgroundColor) {
      const qrcode = new QRCodeStyling({
        width: width,
        height: height,
        margin: 10,
        data: code,
        image: logo,
        dotsOptions: {
          color: dotColor,
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: cornerColor
        },
        backgroundOptions: {
          color: backgroundColor
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 10
        }
      })
      await qrcode._canvasDrawingPromise
      return qrcode._canvas.getCanvas().toDataURL('image/png')
    }
  }
}
