<template>
    <div class="QuickMenuProfileComponent">
      <v-btn class="mx-1 rounded-lg" text to="/dashboard/profile/main">Profile</v-btn>
      <v-btn class="mx-1 rounded-lg" text to="/dashboard/profile/activity">Activity</v-btn>
    </div>
</template>

<script>
export default {
  name: 'QuickMenuProfileComponent'
}
</script>
