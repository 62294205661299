<template>
    <div class="QuickMenuBusinessComponent">
      <v-btn class="mx-1 rounded-lg" text to="/dashboard/business/main">Business</v-btn>
      <v-btn class="mx-1 rounded-lg" text to="/dashboard/business/public">Public Information</v-btn>
      <v-btn class="mx-1 rounded-lg" text to="/dashboard/business/tipsandcharity">Tips and Charity</v-btn>
      <v-btn class="mx-1 rounded-lg" text to="/dashboard/business/payment">Payment</v-btn>
      <v-btn class="mx-1 rounded-lg" text to="/dashboard/business/permissions">Permissions</v-btn>
    </div>
</template>

<script>
export default {
  name: 'QuickMenuBusinessComponent'
}
</script>
